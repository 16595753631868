import * as _ from "lodash";

export default function Throttle(timeout: number, leading?: boolean, trailing?: boolean): MethodDecorator {
    return function (target: Object, key: string | symbol, descriptor: PropertyDescriptor) {
        if(descriptor === undefined) {
            descriptor = Object.getOwnPropertyDescriptor(target, key);
        }
        var originalMethod = descriptor.value;
    
        var throttled = _.throttle(originalMethod, timeout, { leading, trailing});
        descriptor.value = throttled;
    }
}