import * as signalR from '@microsoft/signalr';
import {HubConnection, HubConnectionState, LogLevel} from '@microsoft/signalr';
import authTokenService from 'common/authTokenService';
import loggingService from 'common/logging/loggingService';

export interface HubConnectionManager {
    getConnectionAsync(): Promise<HubConnection>;
    isConnected(): boolean;
    stopAsync(): Promise<any>
}

class HubConnectionManagerImplementation implements HubConnectionManager {
    private _hubConnection: HubConnection;

    constructor(url: string) {
        this._hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(url, { accessTokenFactory: authTokenService.getToken })
            .withAutomaticReconnect()
            .configureLogging(getLogLevel())
            .build();

        this._hubConnection.onreconnected(() => this.onReconnected());
        this._hubConnection.onreconnecting(() => this.onReconnecting());
        this._hubConnection.onclose(() => this.onClose());
    }

    getConnectionAsync(): Promise<HubConnection> {

        if (this._hubConnection.state === HubConnectionState.Disconnected) {
            return new Promise<HubConnection>((resolve, reject) => 
                this._hubConnection.start()
                    .then(() => {
                        resolve(this._hubConnection);
                    })
                    .catch(reject));
        }
        else return Promise.resolve(this._hubConnection);
    }

    isConnected(): boolean {
        return this._hubConnection.state === HubConnectionState.Connected;
    }

    async stopAsync(): Promise<any> {
        if (this.isConnected()){
            loggingService.debug("Stopping SignalR");
            await this._hubConnection.stop();
        }
    }

    private onReconnecting() {
        loggingService.debug("SignalR reconnecting...");
    }

    private onReconnected() {
        loggingService.debug("SignalR reconnected");
    }

    private onClose() {

    }
}

let hubConnection: HubConnectionManager;

export function getConnectionManager(): HubConnectionManager {
    if (hubConnection == null) {
        hubConnection = new HubConnectionManagerImplementation(`${process.env.REACT_APP_PROXY_API_BASE_URL}/gateway/streaming`);
    }

    return hubConnection;
}

function getLogLevel(): LogLevel {
    switch (process.env.REACT_APP_LOG_LEVEL) {
        case "Debug":
            return LogLevel.Debug;
        default:
            return LogLevel.Information;
    }
}