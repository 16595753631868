export enum OfferAction {
    approvePendingOffer = 1,
    switchToMarketOffer,
    cancelOrder,
    placeLimitOrder,
    cancelOffer,
    buyAtTargetPrice,
    placeMarketOrder,
    moveUnfilledToNewOffer,
    moveUnfilledToNewOfferAndCancel,
    dismissAlert
}
