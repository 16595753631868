import { AxiosError } from "axios";
import loggingService from "common/logging/loggingService";
import ValidationError from "common/validationError";

export default function ThrowsValidationError(): MethodDecorator {
    return function (target: Object, key: string | symbol, descriptor: PropertyDescriptor) {
        if(descriptor === undefined) {
            descriptor = Object.getOwnPropertyDescriptor(target, key);
        }
        var originalMethod = descriptor.value;
    

        descriptor.value = function() {
            return Reflect.apply(originalMethod, this, arguments)
                .catch(function(error: AxiosError) {
                    const response = error.response;
                
                    loggingService.error(`Error in ${target.constructor.name}.${key.toString()}`, error);
                
                    if (response.status === 400) {
                        throw new ValidationError((response.data as any).errors)
                    }
                    else if (response.status === 404) {
                        throw new ValidationError(response.data as string[])
                    }
                    else {
                        throw new ValidationError(["An unknown error occurred"]);
                    }
                });
        }
    }
}