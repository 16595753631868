import axios, { AxiosInstance } from "axios";
import authTokenService from "common/authTokenService";
import { CancellationToken } from "common/ui/useAjaxCall";

export interface IAdminHttpClient extends AxiosInstance {
}

export default class AdminHttpClient {
    
    static getProxyClient(merchantId?: string, cxt?: CancellationToken): IAdminHttpClient {
        return this.getClientInternal(merchantId, process.env.REACT_APP_PROXY_API_BASE_URL, cxt);
    }

    private static getClientInternal(merchantId: string, baseUrl: string, cxt?: CancellationToken): IAdminHttpClient {
        const defaultHeaders = {
            Authorization: `Bearer ${authTokenService.getToken()}`,
            'x-merchant-id': merchantId
        };

        var client = axios.create({
            baseURL: baseUrl,
            headers: defaultHeaders,
            signal: cxt?.signal
        });

        const httpClient = new AdminHttpClient();
        return {...client, ...httpClient } as IAdminHttpClient;
    }
}