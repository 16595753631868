export enum RoundingStrategyEnum
{
    HalfCentUp = 1,
    HalfCentDown,
    NoRounding,
    AllDown
}

export function getRoundingStrategyName(roundingStrategy: RoundingStrategyEnum): string {
    switch (roundingStrategy) {
        case RoundingStrategyEnum.HalfCentUp:
            return "1/2 Cent Up";
        case RoundingStrategyEnum.HalfCentDown:
            return "1/2 Cent Down";
        case RoundingStrategyEnum.NoRounding:
            return "No Rounding";
        case RoundingStrategyEnum.AllDown:
            return "All Down";
        default:
            return "";
    }
}

export const AllRoundingStrategyEnums: RoundingStrategyEnum[] = [
    RoundingStrategyEnum.HalfCentUp,
    RoundingStrategyEnum.HalfCentDown,
    RoundingStrategyEnum.NoRounding,
    RoundingStrategyEnum.AllDown
]

export class RoundingStrategy
{
     id: number = 0;
     name: string = "";
}

export const AllRoundingStrategies: RoundingStrategy[] = AllRoundingStrategyEnums.map(id => ({ id, name: getRoundingStrategyName(id)}));