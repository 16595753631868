import * as _ from "lodash";
import IAppStore from "app/IAppStore";
import { useSelector } from "react-redux";

export default function usePermission(permission: string): boolean {
    return useSelector((m: IAppStore) => m.userPermissions != null && m.userPermissions.includes(permission));
}

export function useAnyPermission(...permissions: string[]): boolean {
    return useSelector((m: IAppStore) => m.userPermissions != null && _.intersection(m.userPermissions, permissions).length > 0);
}