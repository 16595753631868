import Offer from "./offer";

export enum OfferStatus {
    Unknown,
    Working = 1,
    Pending = 2,
    Filled = 3,
    Cancelled = 5,
    Expired = 6
}

class OfferStatusConverter {
    public fromString(val: string): OfferStatus {
        switch (val?.toLowerCase()) {
            case "working":
                return OfferStatus.Working;
            case "pending":
                return OfferStatus.Pending;
            case "filled":
                return OfferStatus.Filled;
            case "cancelled":
                return OfferStatus.Cancelled;
            case "expired":
                return OfferStatus.Expired;
            default:
                return OfferStatus.Unknown;
        }
    }

    public toString(offerStatus: OfferStatus): string {
        switch (offerStatus) {
            case OfferStatus.Working:
                return "Working";
            case OfferStatus.Pending:
                return "Pending";
            case OfferStatus.Filled:
                return "Filled";
            case OfferStatus.Cancelled:
                return "Cancelled";
            case OfferStatus.Expired:
                return "Expired";
            default:
                return "Unknown";
        }
    }
}

export function isAllowed(offer: Offer, ...allowedStatuses: OfferStatus[]): boolean {
    return allowedStatuses.some(s => offer.status === s);
}

export const offerStatusConverter = new OfferStatusConverter();