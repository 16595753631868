import Event from "common/event";
import PagedData from "common/pagedData";
import { Dispatch } from "react";
import notificationService from "./notificationService";
import StoneHedgeNotification from "./stoneHedgeNotification";

export class NotificationsLoaded extends Event {
    constructor(public notifications: PagedData<StoneHedgeNotification>, public isUnread: boolean = false) {
        super(NotificationsLoaded.EventName)
    }

    public static EventName: string = "notifications/loaded";
}

export class NotificationReceived extends Event {
    constructor(public notification: StoneHedgeNotification) {
        super(NotificationReceived.EventName)
    }

    public static EventName: string = "notifications/received";
}

export class NotificationRead extends Event {
    constructor(public notification: StoneHedgeNotification) {
        super(NotificationRead.EventName)
    }

    public static EventName: string = "notifications/read";
}

export class AllNotificationsRead extends Event {
    constructor() {
        super(AllNotificationsRead.EventName)
    }

    public static EventName: string = "notifications/all-read";
}

export class NotificationDeleted extends Event {
    constructor(public notificationId: string) {
        super(NotificationDeleted.EventName)
    }

    public static EventName: string = "notifications/deleted";
}

export async function listNotifications(dispatch: Dispatch<any>, pageSize: number, offset: number) {
    const notifications = await notificationService.list(pageSize, offset);
    dispatch(new NotificationsLoaded(notifications).toObject());
}

export async function listUnreadNotifications(dispatch: Dispatch<any>, pageSize: number, offset: number) {
    const notifications = await notificationService.listUnread(pageSize, offset);
    dispatch(new NotificationsLoaded(notifications, true).toObject());
}


export function markNotificationRead(dispatch: Dispatch<any>, notification: StoneHedgeNotification) {
    notificationService.markAsRead(notification);
    dispatch(new NotificationRead(notification).toObject());
}

export function markAllNotificationsRead(dispatch: Dispatch<any>) {
    notificationService.markAllAsRead();
    dispatch(new AllNotificationsRead().toObject());
}

export function deleteNotification(dispatch: Dispatch<any>, notification: StoneHedgeNotification) {
    notificationService.delete(notification);
    dispatch(new NotificationDeleted(notification.id).toObject())
}