import { OfferTypeEnum } from "./offerType";

class OfferTypeHelper {
    
    public isCash(offerType: OfferTypeEnum): boolean {
        return offerType == OfferTypeEnum.Cash || offerType == OfferTypeEnum.CashMinPrice;
    }

    public isHta(offerType: OfferTypeEnum): boolean {
        return offerType == OfferTypeEnum.Hta || offerType == OfferTypeEnum.HtaMinPrice;
    }


    public isPriceLockAllowed(offerType: OfferTypeEnum): boolean {
        return [OfferTypeEnum.Cash, OfferTypeEnum.Hta, OfferTypeEnum.CashMinPrice, OfferTypeEnum.HtaMinPrice, OfferTypeEnum.FixFuturesOnBasis].includes(offerType);
    }

    public hasFuturesPrice(offerType: OfferTypeEnum): boolean {
        return [OfferTypeEnum.Cash, OfferTypeEnum.Hta, OfferTypeEnum.CashMinPrice, OfferTypeEnum.HtaMinPrice, OfferTypeEnum.FixFuturesOnBasis].includes(offerType);
    }

    public hasBasis(offerType: OfferTypeEnum): boolean {
        return [OfferTypeEnum.Cash, OfferTypeEnum.CashMinPrice, OfferTypeEnum.BasisContract].includes(offerType);
    }

    public hasDeliveryMethods(offerType: OfferTypeEnum): boolean {
        return offerType != OfferTypeEnum.DelayedPriceContract;
    }

    public originatesFromOffer(offerType: OfferTypeEnum): boolean {
        return [OfferTypeEnum.Cash, OfferTypeEnum.Hta, OfferTypeEnum.CashMinPrice, OfferTypeEnum.HtaMinPrice, OfferTypeEnum.FixFuturesOnBasis, OfferTypeEnum.FixBasisOnHta].includes(offerType);
    }

    public canHaveDelayedPriceContract(offerType: OfferTypeEnum): boolean {
        return [OfferTypeEnum.Cash, OfferTypeEnum.Hta, OfferTypeEnum.CashMinPrice, OfferTypeEnum.HtaMinPrice, OfferTypeEnum.BasisContract].includes(offerType);
    }

    public canBeHedged(offerType: OfferTypeEnum): boolean {
        return ![OfferTypeEnum.DelayedPriceContract, OfferTypeEnum.BasisContract, OfferTypeEnum.FixBasisOnHta].includes(offerType);
    }

    public canBuyNow(offerType: OfferTypeEnum): boolean {
        return [OfferTypeEnum.Cash, OfferTypeEnum.CashMinPrice, OfferTypeEnum.Hta, OfferTypeEnum.HtaMinPrice].includes(offerType);
    }
}

const offerTypeHelper = new OfferTypeHelper()

export default offerTypeHelper;