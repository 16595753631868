import numeral from 'numeral';

export function formatDecimalPlace(value: number): string {
    if (value == null) return "";

    return numeral(value).format('0,0.00[000]');
}

export function formatNumber(value: number, format: string = '0,0'): string {
    if (value == null)
        return '';
    return numeral(value).format(format);
}

export function formatPricingValue(value: number): string {
    return value == null ? "--" : formatDecimalPlace(value);
}
