import * as _ from 'lodash';
import { getConnectionManager, HubConnectionManager } from 'common/streaming/hubConnectionManager';
import loggingService from 'common/logging/loggingService';
import IDispatcher from "common/dispatcher";
import { FuturesContractPriceUpdated } from "./futuresContracts.actions";
import Throttle from 'common/decorators/throttle';

export class FuturesPriceUpdate {
    symbol: string;
    price: number;
    high: number;
    low: number;
    netChange: number;
    priceDateTime : Date;
    isSettled: boolean;
    isMarketClosed: boolean;
    isTradingHalted: boolean;
}

export interface IPriceStreamingService {
    start(dispatcher: IDispatcher): Promise<any>;
    subscribeToSymbols(symbols: string[]): Promise<any>;
    pause(): void;
    stopAllStreaming(): void;
}

export class PriceStreamingService implements IPriceStreamingService {
    private hubConnectionManager: HubConnectionManager;
    private connectionState: { symbols: string[]} = null;
    private isStarted: boolean;

    constructor() {
        this.hubConnectionManager = getConnectionManager();
    }

    public async start(dispatcher: IDispatcher): Promise<any> {

        if (this.isStarted) return;

        this.isStarted = true;

        const conn = await this.hubConnectionManager.getConnectionAsync();
        conn.onreconnected(() => {
            if (this.connectionState != null) {
                this.subscribeToSymbols(this.connectionState.symbols);
            }
        })

        conn.on(PriceUpdateEvents.PricesDelay, (contract: FuturesPriceUpdate) => {
            this.sendPriceUpdate(dispatcher, contract);
        });
        
        conn.on(PriceUpdateEvents.PricesRealTime, (contract: FuturesPriceUpdate) => {
            this.sendPriceUpdate(dispatcher, contract);
        });
    }

    /* We can receive multiple updates for a price in a single second, try to smooth it out so we're not redrawing all the time */
    private latestContractUpdates: {[symbol: string]: FuturesPriceUpdate} = {};
    private sendPriceUpdate(dispatcher: IDispatcher, contract: FuturesPriceUpdate) {

        this.latestContractUpdates[contract.symbol] = contract;
        this.updateContractPrices(dispatcher);
    }

    @Throttle(1000, true, true)
    private updateContractPrices(dispatcher: IDispatcher) {
        _.mapValues(this.latestContractUpdates, c => dispatcher.dispatch(new FuturesContractPriceUpdated(c).toObject()));

        //Clear out the updates until the next update time
        this.latestContractUpdates = {};
    }

    public async subscribeToSymbols(symbols: string[]): Promise<any> {
        this.connectionState = { symbols };
        var conn = await this.hubConnectionManager.getConnectionAsync();
        await conn.send("SubscribeToSymbols", symbols);
    }

    public async pause(): Promise<any> {
        this.connectionState = null;
        if (!this.hubConnectionManager.isConnected) {
            loggingService.debug("SignalR not connected, ignoring for now");
            return;
        }
        var conn = await this.hubConnectionManager.getConnectionAsync();
        await conn.send("PauseStreaming");
        
    }

    public async stopAllStreaming(): Promise<any> {
        this.connectionState = null;        
        await this.hubConnectionManager.stopAsync();
    }
}

export const PriceUpdateEvents = {
    PricesDelay: "PricesDelay",
    PricesRealTime: "PricesRealTime"
}

const priceStreamingService: IPriceStreamingService = new PriceStreamingService();
export default priceStreamingService;