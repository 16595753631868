import priceStreamingService from 'features/futuresContracts/priceStreamingService';
import { UserLoggedIn } from 'features/login/login.action';
import offerEventStreamingService from 'features/offers/offerEventStreamingService';
import merchantEventStreamingService from 'features/merchants/merchantEventStreamingService';
import notificationStreamingService from 'features/notifications/notificationStreamingService';
import positionBalanceEventStreamingService from 'features/positions/positionBalanceEventStreamingService';
import orderEventStreamingService from 'features/positions/orderEventStreamingService';
import contractEventStreamingService from 'features/contracts/contractEventStreamingService';
import bidChangeEventStreamingService from 'features/bids/bidChangeEventStreamingService';
import deliveryPeriodEventStreamingService from 'features/bidEditor/deliveryPeriodEventStreamingService';
import locationEventStreamingService from 'features/locations/locationEventStreamingService';
import loggingService from 'common/logging/loggingService';
import notificationService from 'common/notificationService';
      
const signalRMiddleware = (store: any) => (next: any) => async (action: any) => {
    // register signalR after the user logged in
    if (action.type === UserLoggedIn.EventName) {

        try {
            await Promise.all([
                priceStreamingService.start(store),

                offerEventStreamingService.start(store),
                merchantEventStreamingService.start(store),
                notificationStreamingService.start(store),
                positionBalanceEventStreamingService.start(store),
                orderEventStreamingService.start(store),
                contractEventStreamingService.start(store),
                bidChangeEventStreamingService.start(store),
                deliveryPeriodEventStreamingService.start(store),
                locationEventStreamingService.start(store)
            ]);
        }
        catch (err) {
            loggingService.error("Error starting SignalR", err as Error);
            notificationService.warning("Streaming updates are currently unavailable")
        }
    }

    return next(action);
};

export default signalRMiddleware;