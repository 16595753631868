import PhysicalLocation from "domain/physicalLocation";
import Commodity from "domain/commodity";
import ThrowsValidationError from "common/http/throwsValidationError";
import Throttle from "common/decorators/throttle";
import ReturnsNullOn404 from "common/http/returnsNullOn404";
import HttpService from "common/http/httpService";

class LocationService extends HttpService {
	@Throttle(1000, true, false)
	async listLocations(): Promise<PhysicalLocation[]> {
		const response = await this.getClient().get<PhysicalLocation[]>('/settings/v1/locations');
		return response.data;
	}

    @ThrowsValidationError()
	async createLocation(location: PhysicalLocation): Promise<PhysicalLocation> {
		const response = await this.getClient().post<PhysicalLocation>('/settings/v1/locations', location);
		return response.data;
	}

    @ThrowsValidationError()
	async updateLocation(location: PhysicalLocation): Promise<any> {
		await this.getClient().put(`/settings/v1/locations/${location.id}`, location);		
	}

    @ThrowsValidationError()
	async updateLocationCommodities(location: PhysicalLocation, commodities: Commodity[]): Promise<any> {
		await this.getClient().put(`/settings/v1/locations/${location.id}/commodities`, commodities.map(m => m.id));
	}

    @ThrowsValidationError()
	async deleteLocation(location: PhysicalLocation): Promise<any> {
		await this.getClient().delete<PhysicalLocation>(`/settings/v1/locations/${location.id}`);		
	}
	
    @ThrowsValidationError()
	async updateCommoditiesForAllLocations(toAdd: Commodity[], toRemove: Commodity[]): Promise<any> {
		await this.getClient().put('/settings/v1/locations/all/commodities', {
			add: toAdd.map(m => m.id),
			remove: toRemove.map(m => m.id)
		});
	}

    @ThrowsValidationError()
	async updateLocationCommodity(location: PhysicalLocation, commodity: Commodity): Promise<any> {
		await this.getClient().put(`/settings/v1/locations/${location.id}/commodities/${commodity.id}`, commodity);
	}

	@ReturnsNullOn404()
	async getLocation(locationId: string): Promise<PhysicalLocation> {
	  const response = await this.getClient().get<PhysicalLocation>(`/settings/v1/locations/${locationId}`);
	  return response.data;
	}

	@ThrowsValidationError()
	async grantAccessToAllTraders(location: PhysicalLocation): Promise<any> {
		await this.getClient().put(`/settings/v1/locations/${location.id}/grant-access-to-all-traders`);	
	}

	@ThrowsValidationError()
	async removeAccessFromAllTraders(location: PhysicalLocation): Promise<any> {
		await this.getClient().put(`/settings/v1/locations/${location.id}/remove-access-from-all-traders`);	
	}

	@ThrowsValidationError()
	async setDefaultBuyLocation(location: PhysicalLocation): Promise<any> {
		await this.getClient().put('/settings/v1/locations/default-buy-location', {
			locationId: location.isDefaultBuyLocation ? location.id : null,
		});	
	}
}

const locationService = new LocationService();
export default locationService;
