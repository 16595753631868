const statuses = {
    New: "new",
    Filled: "filled",
    PartiallyFilled: "partially_filled",
    Rejected: "rejected",
    Canceled: "canceled",
    Replaced: "replaced",
    Parked: "parked",
    ParkedCancel: "parked cancel",
    Suspended: "suspended",
    Expired: "expired"
}

function isInList(status: string, list: string[]) {
    return list.includes(status?.toLowerCase());
}

const cancelableStatuses = [statuses.New, statuses.Replaced, statuses.Parked];
export function canOrderBeCanceled(orderStatus: string) {
    return isInList(orderStatus, cancelableStatuses);
}

const hedgeableStatuses = [statuses.Rejected, statuses.Canceled, statuses.Expired];
export function canOrderBePlaced(orderStatus: string) {
    return orderStatus == null || isInList(orderStatus, hedgeableStatuses);
}

export function isRejected(orderStatus: string) {
    return isInList(orderStatus, [statuses.Rejected]);
}

export function isCanceled(orderStatus: string) {
    return isInList(orderStatus, [statuses.Canceled]);
}

export function isPartiallyFilled(orderStatus: string) {
    return isInList(orderStatus, [statuses.PartiallyFilled]);
}

export function isFilledOrPartiallyFilled(orderStatus: string) {
    return isInList(orderStatus, [statuses.PartiallyFilled, statuses.Filled]);
}

export function isParked(orderStatus: string) {
    return isInList(orderStatus, [statuses.Parked]);
}

export function isParkedCancel(orderStatus: string) {
    return isInList(orderStatus, [statuses.ParkedCancel]);
}

export function canShowOrderId(orderStatus: string): boolean {
    return !isRejected(orderStatus) && !isCanceled(orderStatus);
}

export function getOrderStatusDisplayName(orderStatus: string): string {
    if (orderStatus == null) return null;
    switch (orderStatus.toLowerCase()) {
        case statuses.New:
        case statuses.Replaced:
            return "New";
        case statuses.Filled:
            return "Filled";
        case statuses.Canceled:
            return "Canceled";
        case statuses.Parked:
            return "Parked";
        case statuses.ParkedCancel:
            return "Parked Cancel";
        case statuses.PartiallyFilled:
            return "Partial";
        case statuses.Rejected:
            return "Rejected";
        case statuses.Suspended:
            return "Suspended";
        case statuses.Expired:
            return "Expired";
        default:
            return orderStatus;
    }
}


export default statuses;