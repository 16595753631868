export enum OrderExpirationType {
    GoodTilCancel = 1,
    GoodTilDate,
    Day
}

export const AllOrderExpirationTypes: OrderExpirationType[] = [
    OrderExpirationType.GoodTilCancel,
    OrderExpirationType.GoodTilDate,
    OrderExpirationType.Day
];

export function getOrderExpirationTypeName(orderExpirationType: OrderExpirationType): string {
    switch (orderExpirationType) {
        case OrderExpirationType.GoodTilCancel:
            return "Good Till Cancel";
        case OrderExpirationType.GoodTilDate:
            return "Good Till Date";
        case OrderExpirationType.Day:
            return "Day";
        default:
            return "";
    }
}