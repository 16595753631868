import * as _ from "lodash";

export default function MemoizeDebounce(timeout: number, leading?: boolean, trailing?: boolean): MethodDecorator {

    return function (target: Object, key: string | symbol, descriptor: PropertyDescriptor) {

        if (descriptor === undefined) {
            descriptor = Object.getOwnPropertyDescriptor(target, key);
        }

        var originalMethod = descriptor.value;

        const memoized = _.memoize(function () {
            return _.debounce(originalMethod, timeout, { leading, trailing })
        })

        descriptor.value = function (...args: []) {
            memoized.apply(target, args).apply(target, args);
        };
    }
}