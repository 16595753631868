import locationService from "features/locations/location.service";
import Event from "common/event";
import * as _ from "lodash";
import { ActionWithDispatch } from "common/actionWithDispatch";
import PhysicalLocation from "domain/physicalLocation";
import trackApiCall, { trackApiCallNew } from "features/saveIndicator/trackApiCall";
import Commodity from "domain/commodity";
import { Dispatch } from "redux";
import { PositionGroupAdded, PositionGroupsLoaded } from "features/positions/positionGroups.action";
import positionGroupService from "features/positions/positionGroupService";
import { TradersLoaded } from "features/settings/traders/traders.actions";
import traderService from "features/settings/traders/traderService";

export class LocationsLoaded extends Event {
  constructor(public locations: PhysicalLocation[]) {
    super(LocationsLoaded.EventName);
  }

  public static EventName: string = "LOCATIONS_LOADED";
}

export class LocationAdded extends Event {
  constructor(public newLocation: PhysicalLocation) {
    super(LocationAdded.EventName);
  }

  public static EventName: string = "LOCATION_ADDED";
}

export class LocationUpdated extends Event {
  constructor(public location: PhysicalLocation) {
    super(LocationUpdated.EventName);
  }

  public static EventName: string = "LOCATION_UPDATED";
}

export class LocationCommoditiesUpdated extends Event {
  constructor(public locationId: string, public commodities: Commodity[]) {
    super(LocationCommoditiesUpdated.EventName);
  }

  public static EventName: string = "LOCATION_COMMODITIES_UPDATED";
}

export class LocationCommodityUpdated extends Event {
  constructor(public locationId: string, public commodity: Commodity) {
    super(LocationCommodityUpdated.EventName);
  }

  public static EventName: string = "LOCATION_COMMODITY_UPDATED";
}

export class CommoditiesUpdatedForAllLocations extends Event {
  constructor(public added: Commodity[], public removed: Commodity[]) {
    super(CommoditiesUpdatedForAllLocations.EventName);
  }

  public static EventName: string = "COMMODITIES_UPDATED_FOR_ALL_LOCATIONS";
}

export class LocationDeleted extends Event {
  constructor(public location: PhysicalLocation) {
    super(LocationDeleted.EventName);
  }

  public static EventName: string = "LOCATION_DELETED";
}

export class DefaultBuyLocationSet extends Event {
  constructor(public location: PhysicalLocation) {
    super(DefaultBuyLocationSet.EventName);
  }

  public static EventName: string = "LOCATION_DEFAULT_BUY_SET";
}


export function loadLocations(): ActionWithDispatch<PhysicalLocation[]> {
  return function(dispatch: any) {
    return locationService
      .listLocations()
      .then(locations => dispatch(new LocationsLoaded(locations).toObject()));
  };
}

export function loadLocations_dispatch(dispatch: Dispatch): Promise<PhysicalLocation[]> {
    return locationService
      .listLocations()
      .then(locations => dispatch(new LocationsLoaded(locations).toObject()));
}

export function createLocation(location: PhysicalLocation, commodities: Commodity[], grantAccess: boolean): ActionWithDispatch<Promise<any>> {
  return trackApiCall(async dispatch => {
    const positionGroupId = location.positionGroup?.id;
    const newLocation = await locationService.createLocation(location);
    newLocation.commodities = commodities;

    if(!positionGroupId && newLocation.positionGroup?.id){
      dispatch(new PositionGroupAdded(newLocation.positionGroup).toObject());
    }

    if(grantAccess) {
      await locationService.grantAccessToAllTraders(newLocation);
      const traderSettings = await traderService.listTraders();
      dispatch(new TradersLoaded(traderSettings).toObject());
    }

      await locationService.updateLocationCommodities(newLocation, commodities)
      return dispatch(new LocationAdded(newLocation).toObject());
  });
}

export function createLocation_dispatch(dispatch: Dispatch, location: PhysicalLocation, commodities: Commodity[], grantAccess: boolean): Promise<PhysicalLocation> {
  return trackApiCallNew(dispatch, async innerDispatch => {
    const newLocation = await locationService.createLocation(location);
    newLocation.commodities = commodities;

    if(grantAccess) {
      await locationService.grantAccessToAllTraders(newLocation);
      const traderSettings = await traderService.listTraders();
      dispatch(new TradersLoaded(traderSettings).toObject());
    }

    await locationService.updateLocationCommodities(newLocation, commodities);
    innerDispatch(new LocationAdded(newLocation).toObject());
    return newLocation;
  });
}

export function updateLocation(location: PhysicalLocation): ActionWithDispatch<any> {
  return trackApiCall(async dispatch => {
    return locationService
    .updateLocation(location)
    .then(async () => {
        dispatch(new LocationUpdated(location).toObject());

        if(!location.positionGroup?.id && location.positionGroup?.name) {
            const positionGroups = _.orderBy(await positionGroupService.listPositionGroups(), m => m.name);
            return dispatch(new PositionGroupsLoaded(positionGroups).toObject());
        }
    });
  });
}

export function setDefaultBuyLocation(location: PhysicalLocation): ActionWithDispatch<any> {
  return trackApiCall(async dispatch => {
    return locationService
    .setDefaultBuyLocation(location)
    .then(() => dispatch(new DefaultBuyLocationSet(location).toObject()))
  });
}

export function updateLocationCommodities(location: PhysicalLocation, commodities: Commodity[]): ActionWithDispatch<any> {
  return trackApiCall(dispatch => {
    dispatch(new LocationCommoditiesUpdated(location.id, commodities).toObject());
    return locationService
      .updateLocationCommodities(location, commodities)
      .catch((error) => {
        dispatch(new LocationCommoditiesUpdated(location.id, location.commodities).toObject());
        throw error;
      });
  });
}

export function updateLocationCommodity(location: PhysicalLocation, commodity: Commodity): ActionWithDispatch<any> {
  return trackApiCall(dispatch => {
    dispatch(new LocationCommodityUpdated(location.id, commodity).toObject());
    return locationService
        .updateLocationCommodity(location, commodity)
        .catch((error) => {
          dispatch(new LocationCommoditiesUpdated(location.id, location.commodities).toObject());
          throw error;
        });
  });
}

export function deleteLocation(location: PhysicalLocation): ActionWithDispatch<Promise<any>> {
  return trackApiCall((dispatch) =>
    locationService.deleteLocation(location).then(() => dispatch(new LocationDeleted(location).toObject()))
  );
}

export function updateCommoditiesForAllLocations(toAdd: Commodity[], toRemove: Commodity[]): ActionWithDispatch<any> {
  return trackApiCall(dispatch => locationService
      .updateCommoditiesForAllLocations(toAdd, toRemove)
      .then(() => dispatch(new CommoditiesUpdatedForAllLocations(toAdd, toRemove).toObject()))
  );
}
