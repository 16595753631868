import React from 'react';
import classNames from 'classnames';

export interface ExternalLinkProps {
    url: string;
    hideExternalIcon?: boolean;
    className?: string;
}

const ExternalLink: React.FC<ExternalLinkProps> = props => {
    return <a href={props.url} target="_blank" rel="noopener noreferrer" className={classNames("site-link", props.className)}>
        {props.children} {!props.hideExternalIcon && <i className="material-icons open-external">open_in_new</i> }
    </a>
}

export default ExternalLink;