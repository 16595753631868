import { RoundingStrategyEnum } from 'domain/roundingStrategy';
import * as NP from "number-precision";
import * as _ from "lodash";

export function roundDown(value: number, decimals: number): number { 
    return Number(Math.floor(Number(value + 'e' + decimals)) + 'e-' + decimals); 
}

export function roundCents(price: number, roundingStrategy?: RoundingStrategyEnum) : number {
    if(roundingStrategy == null || roundingStrategy === RoundingStrategyEnum.NoRounding)
            return price;

    // "All down" rounds everything down for fractional cents
    else if(roundingStrategy === RoundingStrategyEnum.AllDown) {
        return NP.divide(Math.trunc(NP.times(price, 100)), 100);
    }

    // If price is ending with half cents like 5.3450, 3.7250
    // somehow it was breaking without ToFixed for "5.1050 * 100 % 1" = 0.5000000000000568
    else if((NP.times(price, 100) % 1).toFixed(2) === '0.50') {
        if (roundingStrategy === RoundingStrategyEnum.HalfCentDown) 
        {
            // 5.3450 will be round to 5.34
            return roundDown(price, 2);
        }
        else if (roundingStrategy === RoundingStrategyEnum.HalfCentUp)
        {
            // 5.3450 will be round to 5.35
            return NP.divide(Math.round(NP.times(price, 100)), 100);
        }
    }
    else {
        return Number.parseFloat(price.toFixed(2));
    }
}

// Adding number array using _.sumBy or _.sum leads to wrong precision.
// Adding _.sum[-1.055, 1.02] returns -0.03499999999999992 and not -0.035
export function sumArray(numbers: number[]): number {
    return _.reduce(numbers, (sum, c) => NP.plus(sum, c ?? 0), 0);
}

export function sumArrayBy<T>(vals: T[], accessor: (item: T) => number): number {
    return _.reduce(vals, (sum, c) => NP.plus(sum, accessor(c) ?? 0), 0);
}