import { ErpSystem } from "domain/erpSystem";
import Commodity from "./commodity";
import { ContractType } from "./contract";

export class Alert {
    alertType: AlertType;
    level: AlertLevel;
    data: any;
}

export enum AlertType {
    ExpiringOffer = 1,
    ExpiringBidEditorSymbols = 2,
    ErpSyncFailureContract = 3,
    ExpiringContract = 4,
    Risk = 5,
    PendingOffers = 6
}

export enum AlertLevel {
    Informational = 1,
    Warning = 2,
    Error = 3
}

export class ExpiringOfferData {
    offerId: number;
    displayId: string;
    expirationDate: Date;
}

export class ExpiringContractData {
    contractId: number;
    displayId: string;
    expirationDate: Date;
}

export class ErpSyncFailureContractData {
    contractId: string;
    displayId: string;
    contractType: ContractType;
    erpSystem: ErpSystem;
}

export class ExpiringBidEditorSymbolData {
    symbol: string;
    expiration: Date;
    commodities: Commodity[];
}