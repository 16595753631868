import AdminHttpService from "admin/common/adminHttpService";
import SystemConfig from "domain/systemConfig";

class SystemConfigService extends AdminHttpService {

    async getSystemConfig(): Promise<SystemConfig> {
        var response = await this.getClient().get<SystemConfig>('/settings/v1/system-config');
        return response.data;
    }

    async updateSystemConfig(enableMasterAutoOffer?: boolean, enableMasterAutoPosition?: boolean): Promise<any> {
        await this.getClient().post('/settings/v1/system-config', { 
            enableMasterAutoOffer: enableMasterAutoOffer,
            enableMasterAutoPosition: enableMasterAutoPosition
         });
    }
}

const systemConfigService = new SystemConfigService();

export default systemConfigService;