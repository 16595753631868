import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { LicenseManager } from "@ag-grid-enterprise/core";
import Loadable from "react-loadable";

import * as serviceWorker from './serviceWorker';
import ModuleManager from './app/moduleManager';
import { ClientSideRowModelModule, ModuleRegistry,  CsvExportModule} from '@ag-grid-community/all-modules';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
import './index.scss'
import { Spinner } from 'react-bootstrap';
import authTokenService from 'common/authTokenService';
import OktaApp from 'common/security/OktaApp';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'common/logging/appInsights';

/* Setup ag-grid */
LicenseManager.setLicenseKey("CompanyName=Stonex Group Inc.,LicensedGroup=technology-solutions-dev,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=0,AssetReference=AG-025102,ExpiryDate=17_March_2023_[v2]_MTY3OTAxMTIwMDAwMA==fb9dea8525e7483bbf9e20f4a848329b");
ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ServerSideRowModelModule,
    CsvExportModule,
    ExcelExportModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    SideBarModule
]);


function renderLoadable(loaded: any) {
    const moduleManager = new ModuleManager();
    moduleManager.loadModules([...loaded.features.default]);

    const token = authTokenService.getToken();
    let appStore: any = {};
    if (token) {
        appStore.userState = {
            isLoggedIn: true,
            accessToken: token
        }
    }
    const store = moduleManager.getStore(appStore);

    const Component = loaded.App.default;
    return <ReduxProvider store={store}>
        <AppInsightsContext.Provider value={reactPlugin}>
            <OktaApp>
                <Component moduleManager={moduleManager} />
            </OktaApp>
        </AppInsightsContext.Provider>
    </ReduxProvider>;
}

function renderComponent(loaded: any) {
    const moduleManager = new ModuleManager();
    moduleManager.loadModules([...loaded.features.default]);
    
    const Component = loaded.App.default;
    return <Component moduleManager={moduleManager} />;
}

const SimpleSpinner: React.FC<any> = (props) => {
    return <Spinner animation={"border"} />;
}

const LoadableApp = Loadable.Map({
    loader:  {
        App: () => import('app/App'),
        features: () => import('features')
    },
    render: renderLoadable,
    loading: SimpleSpinner
});

const LoadableAdminApp = Loadable.Map({
    loader:  {
        App: () => import('admin/app/AdminApp'),
        features: () => import('admin/features')
    },
    render: renderLoadable,
    loading: SimpleSpinner
})

const LoadableDocsApp = Loadable.Map({
    loader:  {
        App: () => import('docs/app/DocsApp'),
        features: () => import('docs/features')
    },
    render: renderLoadable,
    loading: SimpleSpinner
})

const LoadableSignupApp = Loadable.Map({
    loader:  {
        App: () => import('signup/app/SignupApp'),
        features: () => import('signup/features')
    },
    render: renderLoadable,
    loading: SimpleSpinner
})

const LoadableExternalApp = Loadable.Map({
    loader:  {
        App: () => import('external/ExternalApp'),
        features: () => import('external/features')
    },
    render: renderComponent,
    loading: SimpleSpinner
})


ReactDOM.render(
    <Router>
        <Switch>
            <Route path="/admin">
                <LoadableAdminApp />
            </Route>
            <Route path="/docs">
                <LoadableDocsApp />
            </Route>
            <Route path="/signup">
                <LoadableSignupApp />
            </Route>
            <Route path="/component">
                <LoadableExternalApp />
            </Route>
            <Route>
                <LoadableApp />
            </Route>
            
        </Switch>
    </Router>, document.querySelector('#root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
