import { CancellationToken } from "common/ui/useAjaxCall";
import HttpClient from "./httpClient";
import cloneClass from "common/cloneClass";
import loggingService from "common/logging/loggingService";
import notificationService from "common/notificationService";

export default class HttpService {

    private _cxt: CancellationToken = null;


    public getClient() {
        return HttpClient.getProxyClient(null, this._cxt);
    }

    public getMerchantClient(merchantId: string) {
        return HttpClient.getProxyClient(merchantId, this._cxt);
    }

    public setCancellationToken(cxt: CancellationToken) {
        this._cxt = cxt;
    }
}

export function withCancellationToken<T extends HttpService>(service: T, cxt: CancellationToken): T {
    
    const clone = cloneClass(service);
    clone.setCancellationToken(cxt);

    return clone;
}

function isCanceledTokenError(err: unknown): boolean {
    return (err as any)?.code === "ERR_CANCELED";
}

export function logHttpError(message: string, err: unknown, notificationMessage?: string) {
    if (isCanceledTokenError(err)) return;

    loggingService.error(message, err as Error);

    if (notificationMessage != null) {
        notificationService.error(notificationMessage);
    }
}