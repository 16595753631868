export default class PatchOperation {

    constructor(operationType: OperationType, public path: string, public value: any) {
        this.op = getOperationTypeString(operationType);
    }
    op: string;
}

function getOperationTypeString(op: OperationType) {
    switch (op) {
        case OperationType.Replace:
            return "replace";
        case OperationType.Add:
            return "add";
        case OperationType.Remove:
            return "remove";
        case OperationType.Copy:
            return "copy";
        case OperationType.Move:
            return "move";
        case OperationType.Test:
            return "test";
        default:
            throw new Error(`Unrecognized operation type ${op}`)
    }
}

export enum OperationType {
    Replace,

    //Below are included to comply with spec
    Add,
    Remove,
    Copy,
    Move,
    Test
}