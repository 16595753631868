import ThrowsValidationError from "common/http/throwsValidationError";
import PagedData, { toPagedData } from "common/pagedData";
import NotificationPreference from "./notificationPreference";
import StoneHedgeNotification from "./stoneHedgeNotification";
import ListItemsResponse from "common/listItemsResponse";
import HttpService from "common/http/httpService";

type NotificationPreferenceResponse = {
    notificationType: string;
    rule: string;
    isEmailUnsubscribedByUser: boolean;
    displayOrder: number;
}

class NotificationService extends HttpService {

    async list(pageSize: number, offset: number): Promise<PagedData<StoneHedgeNotification>> {
        const response = await this.getClient().get<ListItemsResponse<StoneHedgeNotification>>('/gateway/v1/notifications', {
            params: {
                pageSize,
                offset
            }
        });
        return toPagedData(response.data);
    }
    
    async listUnread(pageSize: number, offset: number, type: string = null): Promise<PagedData<StoneHedgeNotification>> {
        const response = await this.getClient().get<ListItemsResponse<StoneHedgeNotification>>('/gateway/v1/notifications/unread', {
            params: {
                pageSize,
                offset,
                type
            }
        });
        return toPagedData(response.data);
    }
    
    @ThrowsValidationError()
    markAsRead(notification: StoneHedgeNotification): Promise<any> {
        return this.getClient().post(`gateway/v1/notifications/${notification.id}/read`);
    }
    
    @ThrowsValidationError()
    markAllAsRead(): Promise<any> {
        return this.getClient().post(`gateway/v1/notifications/all/read`);
    }
    
    @ThrowsValidationError()
    delete(notification: StoneHedgeNotification): Promise<any> {
        return this.getClient().delete(`gateway/v1/notifications/${notification.id}`);
    }
    
    async listPreferences(): Promise<NotificationPreference[]> {
        const response = await this.getClient().get<NotificationPreferenceResponse[]>('/gateway/v1/users/me/notification-preferences');
        return response.data.map(m => ({
            notificationType: m.notificationType,
            rule: JSON.parse(m.rule),
            isEmailUnsubscribedByUser: m.isEmailUnsubscribedByUser,
            displayOrder: m.displayOrder
        }));
    }

    @ThrowsValidationError()
    updatePreference(preference: NotificationPreference): Promise<void> {
        return this.getClient().put(`gateway/v1/users/me/notification-preferences/${preference.notificationType}`, {
            rule: JSON.stringify(preference.rule)
        });
    }

    async listPreferencesForCustomer(producerId: string): Promise<NotificationPreference[]> {
        const response = await this.getClient().get<NotificationPreferenceResponse[]>(`gateway/v1/users/${producerId}/notification-preferences`);
        return response.data.map(m => ({
            notificationType: m.notificationType,
            rule: JSON.parse(m.rule),
            isEmailUnsubscribedByUser: m.isEmailUnsubscribedByUser,
            displayOrder: m.displayOrder
        }));
    }

    @ThrowsValidationError()
    updatePreferenceForCustomer(producerId: string, preference: NotificationPreference): Promise<void> {
        return this.getClient().put(`gateway/v1/users/${producerId}/notification-preferences/${preference.notificationType}`, {
            rule: JSON.stringify(preference.rule)
        });
    }
}

const notificationService = new NotificationService();
export default notificationService;