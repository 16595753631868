import { Trader } from "./trader";
import traderService from "./traderService";
import Event from "common/event";
import { ActionWithDispatch } from "common/actionWithDispatch";
import TraderConfiguration from "./traderConfiguration";
import { trackApiCallNew } from "features/saveIndicator/trackApiCall";
import { Dispatch } from "redux";

export class TradersLoaded extends Event {
  constructor(public traders: Trader[]) {
    super(TradersLoaded.EventName);
  }

  public static EventName: string = "TRADERS_LOADED";
}

export class TraderConfigurationLoaded extends Event {
  constructor(public configuration: TraderConfiguration) {
    super(TraderConfigurationLoaded.EventName);
  }

  public static EventName: string = "TRADER_CONFIGURATION_LOADED";
}

export class TraderAdded extends Event {
  constructor(public trader: Trader) {
    super(TraderAdded.EventName);
  }

  public static EventName: string = "TRADER_ADDED";
}

export class TraderUpdated extends Event {
  constructor(public trader: Trader) {
    super(TraderUpdated.EventName);
  }

  public static EventName: string = "TRADER_UPDATED";
}

export class TraderDeleted extends Event {
	constructor(public trader: Trader) {
		super(TraderDeleted.EventName);
	}

	public static EventName: string = "TRADER_DELETED";
}

export function loadTraders(): ActionWithDispatch<Trader[]> {
  return function(dispatch: any) {
    return traderService
      .listTraders()
      .then(traderSettings =>
        dispatch(new TradersLoaded(traderSettings).toObject())
      );
  };
}

export function loadTraders_dispatch(dispatch: Dispatch): Promise<Trader[]> {
    return traderService
      .listTraders()
      .then(traderSettings =>
        dispatch(new TradersLoaded(traderSettings).toObject())
      );
}

export function loadTraderConfiguration(dispatch: Dispatch): Promise<TraderConfiguration> {
    return traderService
      .getTraderConfiguration()
      .then(data => dispatch(new TraderConfigurationLoaded(data).toObject()));
}

export function createTrader(dispatch: Dispatch, trader: Trader): Promise<any> {
  return trackApiCallNew(dispatch, innerDispatch => 
    traderService.createTrader(trader)
      .then((newTrader) => innerDispatch(new TraderAdded(newTrader).toObject()))
  );
}

export function updateTrader(dispatch: Dispatch, newValue: Trader, oldValue: Trader): Promise<any> {
  return trackApiCallNew(dispatch, innerDispatch => {
    innerDispatch(new TraderUpdated(newValue).toObject());
    return traderService
      .updateTrader(newValue)
      .catch((error) => {
        dispatch(new TraderUpdated(oldValue).toObject());
        throw error;
      });
  });
}

export function updateTraderCellPhone(dispatch: Dispatch, newValue: Trader, oldValue: Trader): Promise<any> {
  return trackApiCallNew(dispatch, innerDispatch => {
    innerDispatch(new TraderUpdated(newValue).toObject());
    return traderService
      .updateTraderCellPhone(newValue)
      .catch((error) => {
        dispatch(new TraderUpdated(oldValue).toObject());
        throw error;
      });
  });
}

export function deleteTrader(dispatch: Dispatch, trader: Trader): Promise<any> {
	return trackApiCallNew(dispatch, innerDispatch =>
		traderService.deleteTrader(trader).then(() => innerDispatch(new TraderDeleted(trader).toObject()))
	);
}


