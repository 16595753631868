import { getConnectionManager, HubConnectionManager } from 'common/streaming/hubConnectionManager';
import IDispatcher from "common/dispatcher";
import contractService from './contractService';
import { ContractAdded, ContractUpdated } from './contracts.actions';

export interface ContractEvent {
    contractId: number;
}


export interface IContractEventStreamingService {
    start(dispatcher: IDispatcher): Promise<any>;
}

export class ContractEventStreamingService implements IContractEventStreamingService {
    private hubConnectionManager: HubConnectionManager;
    private dispatcher: IDispatcher;
    private isStarted: boolean;

    constructor() {
        this.hubConnectionManager = getConnectionManager();
    }

    public async start(dispatcher: IDispatcher): Promise<any> {
        if (this.isStarted) return;

        this.isStarted = true;

        this.dispatcher = dispatcher;
        var conn = await this.hubConnectionManager.getConnectionAsync();
        conn.on(ContractEvents.ContractUpdated, ev => this.onContractUpdated(ev));
        conn.on(ContractEvents.ContractCreated, ev => this.onContractCreated(ev));
    }
    
    private async onContractCreated(contractEvent: ContractEvent) {
        const contract = await contractService.getContract(contractEvent.contractId); 
        this.dispatcher.dispatch(new ContractAdded(contract).toObject());
    }

    private async onContractUpdated(contractEvent: ContractEvent) {
        const contract = await contractService.getContract(contractEvent.contractId); 
        this.dispatcher.dispatch(new ContractUpdated(contract).toObject());
    }
}

export const ContractEvents = {
    ContractCreated: "ContractCreated",
    ContractUpdated: "ContractUpdated"
}

const contractEventStreamingService: IContractEventStreamingService = new ContractEventStreamingService();
export default contractEventStreamingService;