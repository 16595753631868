import Event from "common/event";
import { ActionWithDispatch } from "common/actionWithDispatch";
import { Dispatch } from "redux";
import systemConfigService from "./systemConfigService";
import SystemConfig from "domain/systemConfig";

export class SystemConfigUpdated extends Event {
    constructor(public enableMasterAutoOffer?: boolean, public enableMasterAutoPosition?: boolean) {
        super(SystemConfigUpdated.EventName);
    }

    public static EventName: string = "system-config/updated";
}

export class SystemConfigLoaded extends Event {
    constructor(public systemConfig: SystemConfig) {
        super(SystemConfigLoaded.EventName);
    }

    public static EventName: string = "system-config/loaded";
}

export function updateSystemConfig(enableMasterAutoOffer?: boolean, enableMasterAutoPosition?: boolean): ActionWithDispatch<Promise<any>> {
    return function (dispatch: any) {
        return systemConfigService
            .updateSystemConfig(enableMasterAutoOffer, enableMasterAutoPosition)
            .then(dispatch(new SystemConfigUpdated(enableMasterAutoOffer, enableMasterAutoPosition).toObject()));
    };
}

export async function loadSystemConfig(dispatch: Dispatch<any>) {
    const systemConfig = await systemConfigService.getSystemConfig();
    dispatch(new SystemConfigLoaded(systemConfig).toObject());
}