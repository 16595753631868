import { AxiosError } from "axios";

export default function ThrowsAccessDenied(): MethodDecorator {
    return function (target: Object, key: string | symbol, descriptor: PropertyDescriptor) {
        if(descriptor === undefined) {
            descriptor = Object.getOwnPropertyDescriptor(target, key);
        }
        var originalMethod = descriptor.value;
    

        descriptor.value = function() {
            return Reflect.apply(originalMethod, this, arguments)
                .catch(function(error: unknown): any {
                    if (error instanceof AxiosError) {
                        const response = error.response;
                                    
                        if (response.status === 403) {
                            throw new AccessDeniedError();
                        }
                        
                    }
                    throw error;
                });
        }
    }
}

export class AccessDeniedError extends Error {
    constructor() {
        super("Access denied");
    }
}