import React from "react";
import { CellClickedEvent, ColDef, CellClassParams, ITooltipParams, GetQuickFilterTextParams, CellClassRules, ValueGetterFunc } from "@ag-grid-community/all-modules";
import RenderParameters from "./renderParameters";
import { Form } from "react-bootstrap";
import { formatDate, formatDecimalPlace, formatUTCDate } from "common/formatters";
import DropdownOption from "common/dropdownOption";

export class ColumnBuilder {
    build(header: string, field?: string, options?: ColDef): ColDef {
        return {
            headerName: header,
            field,
            ...options
        };
    }

    collection(builderFunc: (b: ColumnBuilder) => ColDefBuilder[]): ColDef[] {
        var builder = new ColumnBuilder();

        return builderFunc(builder).map(m => m.build());
    }

    for(header: string, field?: string, options?: ColDef): ColDefBuilder {
        return new ColDefBuilder({
            headerName: header,
            field,
            ...options
        })
    }

    buildCheckbox<T>(header: string, field: string, onChange: (data: T, isChecked: boolean) => any, options?: ColDef): ColDef {
        return this.build(header, field, {
            getQuickFilterText: () => "",
            cellRenderer: (params: RenderParameters) => {
                return (
                    <Form.Check name={field} id={field + params.node.id} custom label=""
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(params.node.data, event.target.checked)}
                        checked={params.node.data[field]} />
                );
            },
            ...options
        })
    }

    buildDropdown<T1>(header: string, field: string, dropdownOptions: DropdownOption[], onChange: (data: T1, value: string) => any, options?: ColDef): ColDef {
        return this.build(header, field, {
            getQuickFilterText: () => "",
            cellRenderer: (params: RenderParameters) => {
                return (
                    <Form.Control
                        as="select"
                        defaultValue={params.node.data[field]}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(params.node.data, event.target.value)}
                    >
                        {dropdownOptions.map(opt => (
                            <option key={opt.value} value={opt.value}>
                                {opt.text}
                            </option>
                        ))}
                    </Form.Control>
                );
            },
            ...options
        })
    }

}

export class ColDefBuilder {

    constructor(private colDef: ColDef) {}

    asNumber(): ColDefBuilder {
        this.colDef.valueFormatter = 'value.toLocaleString()';
        this.colDef.type = "numericColumn";
        return this;
    }

    asDecimal(placeholder?: string): ColDefBuilder {
        this.colDef.valueFormatter = params => params.value == null ? placeholder : formatDecimalPlace(params.value);
        this.colDef.type = "numericColumn";
        return this;
    }

    asUtcDate(format?: string): ColDefBuilder {
        this.colDef.valueFormatter = params => formatUTCDate(params.value, format);
        return this;
    }

    asDate(format?: string): ColDefBuilder {
        this.colDef.valueFormatter = params => formatDate(params.value, format);
        return this;
    }

    asDateTime(): ColDefBuilder {
        return this.asDate("MM/DD/YY LTS")
    }

    asDraggable(isDraggable?: boolean): ColDefBuilder {
        this.colDef.rowDrag = isDraggable ?? true;
        return this;
    }

    asRightAligned(): ColDefBuilder {
        this.colDef.type = 'rightAligned';
        return this;
    }

    withCellClass(className: string | ((params: CellClassParams) => any)) {
        this.colDef.cellClass = className;
        return this;
    }

    withCellClassRules(classRules: CellClassRules) {
        this.colDef.cellClassRules = classRules;
        return this;
    }

    withWidth(minWidth: number, width: number = null): ColDefBuilder {
        this.colDef.width = width ?? minWidth;
        this.colDef.minWidth = minWidth;
        return this;
    }

    withFlex(flex: number):ColDefBuilder {
        this.colDef.flex = flex;
        return this;
    }

    withGetter(getter: ValueGetterFunc): ColDefBuilder {
        this.colDef.valueGetter = m => {
            try {
                return getter(m);
            }
            catch {
                return null;
            }
        } ;
        return this;
    }

    withFilterGetter(getter: (input: any) => string): ColDefBuilder {
        this.colDef.filterValueGetter = params => {
            try {
                return getter(params.data);
            }
            catch {
                return null;
            }
        } ;
        return this;
    }

    //Used to check if the cell needs to refresh
    withEquals(comparator: (input1: any, input2: any) => boolean): ColDefBuilder {
        this.colDef.equals = comparator;
        return this;
    }

    //Used for sorting
    withComparator(comparator: (input1: any, input2: any) => any): ColDefBuilder {
        this.colDef.comparator = comparator;
        return this;
    }

    withTooltipValueGetter(getter: (input: ITooltipParams) => any): ColDefBuilder {
        this.colDef.tooltipValueGetter = getter;
        return this;
    }

    withFormatter(formatter: (input: any) => string): ColDefBuilder {
        this.colDef.valueFormatter = params => params.node.group ? null : formatter(params.value);
        return this;
    }

    withSort(sort: ("asc" | "desc")): ColDefBuilder {
        this.colDef.sort = sort;
        return this;
    }

    withHeaderComponent(component: any): ColDefBuilder {
        this.colDef.headerComponent = component;
        return this;
    }

    withCellRenderer<T>(renderer: (params: RenderParameters<T>) => any): ColDefBuilder {
        this.colDef.cellRenderer = (params: RenderParameters<T>) => { 
            try {
                return renderer(params);
            }
            catch {
                return null;
            }
        };
        return this;
    }

    onCellClick(handler: ((ev: CellClickedEvent) => void)): ColDefBuilder {
        this.colDef.onCellClicked = handler;
        return this;
    }

    withCellRendererParams(params: any): ColDefBuilder {
        this.colDef.cellRendererParams = params;
        return this;
    }

    withValueCellRenderer<T, TValue>(renderer: (params: RenderParameters<T, TValue>) => any): ColDefBuilder {
        this.colDef.cellRenderer = renderer;
        return this;
    }

    withPinnedRowCellRenderer<T, TValue>(renderer: (params: RenderParameters<T, TValue>) => any): ColDefBuilder {
        this.colDef.cellRendererSelector = params => {
            if (params.node.rowPinned) {
                return {
                    component: renderer
                }
            }
        }
        return this;
    }

    flashOnChange(): ColDefBuilder {
        this.colDef.enableCellChangeFlash = true;
        return this;
    }
    
    withColumnClass(cellClassName: string | string[], headerClassName: string | string[]) {
        this.colDef.headerClass = headerClassName;
        this.colDef.cellClass = cellClassName;
        return this;
    }

    withSearchFilter(func: ((params: GetQuickFilterTextParams) => string)): ColDefBuilder {
        this.colDef.getQuickFilterText = func;
        return this;
    }

    withoutSearchFilter(): ColDefBuilder {
        this.colDef.getQuickFilterText = getEmptyString;
        return this;
    }

    build(): ColDef {
        return this.colDef;
    }
}

function getEmptyString(): string { return ""; }


const columnBuilder = new ColumnBuilder();
export default columnBuilder;