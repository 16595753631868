import { getConnectionManager, HubConnectionManager } from 'common/streaming/hubConnectionManager';
import IDispatcher from "common/dispatcher";
import locationService from 'features/locations/location.service';
import { LocationCommoditiesUpdated } from './locations.action';

interface LocationEvent {
    locationId: string;
}

export interface ILocationEventStreamingService {
    start(dispatcher: IDispatcher): Promise<any>;
}

export class LocationEventStreamingService implements ILocationEventStreamingService {
    private hubConnectionManager: HubConnectionManager;
    private dispatcher: IDispatcher;
    private isStarted: boolean;

    constructor() {
        this.hubConnectionManager = getConnectionManager();
    }

    public async start(dispatcher: IDispatcher): Promise<any> {
        if (this.isStarted) return;

        this.isStarted = true;

        this.dispatcher = dispatcher;
        var conn = await this.hubConnectionManager.getConnectionAsync();
        conn.on("LocationUpdated", ev => this.onLocationUpdated(ev.locationId, dispatcher));
    }

    private async onLocationUpdated(locationId: string, dispatcher: IDispatcher) {
        const location = await locationService.getLocation(locationId); 
        dispatcher.dispatch(new LocationCommoditiesUpdated(location.id, location.commodities).toObject());
    }
}

const locationEventStreamingService: ILocationEventStreamingService = new LocationEventStreamingService();
export default locationEventStreamingService;