import Offer from "domain/offer";
import offerService, { ListOffersFilter } from "./offerService";
import Event from "common/event";
import { ActionWithDispatch } from "common/actionWithDispatch";
import { defaultOfferColumns } from "./OfferListColumns";
import OfferColumnConfigurations from "./offerColumnConfigurations";
import { OfferStatus } from "domain/offerStatus";
import PagedData from "common/pagedData";
import trackApiCall from "features/saveIndicator/trackApiCall";
import { Dispatch } from "redux";
import loggingService from "common/logging/loggingService";

export class OffersLoaded extends Event {
    constructor(public status: OfferStatus, public offers: PagedData<Offer>) {
        super(OffersLoaded.EventName)
    }

    public static EventName: string = "OFFERS_LOADED";
}

export class OfferColumnConfigurationsLoaded extends Event {
    constructor(public columnConfigs: OfferColumnConfigurations) {
        super(OfferColumnConfigurationsLoaded.EventName)
    }

    public static EventName: string = "OFFER_COLUMNS_LOADED";
}

export class OfferAdded extends Event {
    constructor(public offer: Offer) {
        super(OfferAdded.EventName)
    }

    public static EventName: string = "OFFER_ADDED";
}

export class OffersAdded extends Event {
    constructor(public offers: Offer[]) {
        super(OffersAdded.EventName)
    }

    public static EventName: string = "OFFERS_ADDED";
}

export class OfferUpdated extends Event {
    constructor(public offer: Offer, public initialStatus: OfferStatus) {
        super(OfferUpdated.EventName)
    }

    public static EventName: string = "OFFER_UPDATED";
}

export class OfferStatusUpdated extends Event {
    constructor(public offer: Offer, public oldStatus: OfferStatus) {
        super(OfferStatusUpdated.EventName)
    }

    public static EventName: string = "OFFER_STATUS_UPDATED";
}

export function loadOffers(status: OfferStatus, filter: ListOffersFilter): ActionWithDispatch<Promise<any>> {

    filter.status = status;

    return function (dispatch: any) {
        return offerService
            .listOffers(filter)
            .then(offers => dispatch(new OffersLoaded(status, { data: offers.results, totalCount: offers.totalCount }).toObject()))
            .catch((err) => {
                loggingService.error('Failed to load Offers', err);
                throw err;
            });
    }
}

export function createOffer(offer: Offer, placeHedgeOrder: boolean): ActionWithDispatch<Promise<any>> {

    return trackApiCall(dispatch =>
        offerService.createOffer(offer, placeHedgeOrder)
            .then((createdOffer) => dispatch(new OfferAdded(createdOffer).toObject()))
    );
}

export function priceBalances(offer: Offer, placeHedgeOrder: boolean): ActionWithDispatch<Promise<any>> {
    return trackApiCall(dispatch =>
        offerService.priceBalances(offer, placeHedgeOrder)
            .then((offers) => dispatch(new OffersAdded(offers.results).toObject()))
    );
}

export function updateOffer(offer: Offer, placeHedgeOrder: boolean): ActionWithDispatch<Promise<any>> {

    return trackApiCall(dispatch =>
        offerService.updateOffer(offer, placeHedgeOrder)
            .then((updatedOffer) => dispatch(new OfferUpdated(updatedOffer, offer.status).toObject()))
    );
}

export function loadOfferColumnConfigurations(dispatch: Dispatch<any>): any {
    return offerService
            .getColumnConfigurations()
            .then(columns => dispatch(new OfferColumnConfigurationsLoaded(columns ?? defaultOfferColumns).toObject()));
}

export function saveOfferColumnConfigurations(dispatch: Dispatch<any>, config: OfferColumnConfigurations): any {
    offerService.updateColumnConfigurations(config);
    dispatch(new OfferColumnConfigurationsLoaded(config).toObject());
}

export function approvePendingOffer(offer: Offer): ActionWithDispatch<Promise<any>> {
    return trackApiCall(dispatch =>
        offerService.approvePendingOffer(offer)
            .then((updatedOffer) => dispatch(new OfferUpdated(updatedOffer, offer.status).toObject()))
    );
}

export function placeLimitOrder(offer: Offer): ActionWithDispatch<Promise<any>> {
    return trackApiCall(dispatch =>
        offerService.placeLimitOrder(offer)
            .then((updatedOffer) => dispatch(new OfferUpdated(updatedOffer, offer.status).toObject()))
    );
}

export function cancelOffer(offer: Offer): ActionWithDispatch<Promise<any>> {
    return trackApiCall(dispatch =>
        offerService.cancelOffer(offer)
            .then((updatedOffer) => dispatch(new OfferUpdated(updatedOffer, offer.status).toObject()))
    );
}

export function cancelHedgeOrder(offer: Offer): ActionWithDispatch<Promise<any>> {
    return trackApiCall(dispatch =>
        offerService.cancelHedgeOrder(offer)
            .then((updatedOffer) => dispatch(new OfferUpdated(updatedOffer, offer.status).toObject()))
    );
}

export function buyAtTargetPrice(offer: Offer): ActionWithDispatch<Promise<any>> {
    return trackApiCall(dispatch =>
        offerService.buyAtTargetPrice(offer)
            .then((updatedOffer) => dispatch(new OfferUpdated(updatedOffer, offer.status).toObject()))
    );
}

export function placeMarketOrder(offer: Offer): ActionWithDispatch<Promise<any>> {
    return trackApiCall(dispatch =>
        offerService.placeMarketOrder(offer)
            .then((updatedOffer) => dispatch(new OfferUpdated(updatedOffer, offer.status).toObject()))
    );
}

export function switchToMarketOffer(offer: Offer): ActionWithDispatch<Promise<any>> {
    return trackApiCall(dispatch =>
        offerService.switchToMarketOffer(offer)
            .then((updatedOffer) => dispatch(new OfferUpdated(updatedOffer, offer.status).toObject()))
    );
}

export function moveUnfilledToNewOffer(offer: Offer, cancelNewOffer: boolean): ActionWithDispatch<Promise<any>> {
    return trackApiCall(dispatch =>
        offerService.moveUnfilledToNewOffer(offer, cancelNewOffer)
            .then((updatedOffer) => dispatch(new OfferUpdated(updatedOffer, offer.status).toObject()))
    );
}

export function dismissAlert(offer: Offer): ActionWithDispatch<Promise<any>> {

    return trackApiCall(dispatch =>
        offerService.dismissAlert(offer)
            .then((updatedOffer) => dispatch(new OfferUpdated(updatedOffer, offer.status).toObject()))
    );
}
