import { getConnectionManager, HubConnectionManager } from 'common/streaming/hubConnectionManager';
import IDispatcher from "common/dispatcher";
import deliveryPeriodService from './deliveryPeriod.service';
import { DeliveryPeriodUpdated } from './deliveryPeriod.actions';
import MemoizeDebounce from 'common/decorators/memoizedDebounce';

interface BidChangeEvent {
    deliveryPeriodId: string;
}

export interface IBidChangeEventStreamingService {
    start(dispatcher: IDispatcher): Promise<any>;
}

export class BidChangeEventStreamingService implements IBidChangeEventStreamingService {
    private hubConnectionManager: HubConnectionManager;
    private dispatcher: IDispatcher;
    private isStarted: boolean;

    constructor() {
        this.hubConnectionManager = getConnectionManager();
    }

    public async start(dispatcher: IDispatcher): Promise<any> {
        if (this.isStarted) return;

        this.isStarted = true;

        this.dispatcher = dispatcher;
        var conn = await this.hubConnectionManager.getConnectionAsync();
        conn.on("BidUpdated", ev => this.onBidUpdated(ev.deliveryPeriodId, dispatcher));
    }

    @MemoizeDebounce(1000, true, false)
    private async onBidUpdated(deliveryPeriodId: string, dispatcher: IDispatcher) {
        const deliveryPeriod = await deliveryPeriodService.getDeliveryPeriod(deliveryPeriodId); 
        dispatcher.dispatch(new DeliveryPeriodUpdated(deliveryPeriod).toObject());
    }
}

const bidChangeEventStreamingService: IBidChangeEventStreamingService = new BidChangeEventStreamingService();
export default bidChangeEventStreamingService;