import HttpService from 'common/http/httpService';
import { Alert, AlertLevel } from 'domain/alert';

class AlertService extends HttpService {


    async overrideCommodityLimit(riskAlertId: number, commodityId: number, isSale: boolean, isAutoPosition: boolean): Promise<any> {
        await this.getClient().post(
            `settings/v1/risk/commodity-limits/${commodityId}/override`,
            {
                riskAlertId: riskAlertId,
                isSale: isSale,
                isAutoPosition: isAutoPosition
            })
    }

    async acknowledgeRejectedContract(riskAlertId: number, erpId: string, erpDisplayId: string, isContractApproved: boolean): Promise<any> {
        await this.getClient().put(
            `settings/v1/risk/acknowledge-rejected-contract`,
            {
                riskAlertId: riskAlertId,
                erpId: erpId, 
                erpDisplayId: erpDisplayId,
                isContractApproved: isContractApproved
            })
    }

    async acknowledgeFrequencyAlert(alertId: any, commodityId: any): Promise<any> {
        await this.getClient().put(
            `settings/v1/risk/acknowledge-frequency-alert`,
            {
                riskAlertId: alertId,
                commodityId: commodityId
            })
    }

    async getAlerts(level?: AlertLevel): Promise<Alert[]> {
        const response = await this.getClient().get<Alert[]>('gateway/v1/alerts', {
            params: {
                level: level
            }
        });
        return response.data;
    }
}

const alertService = new AlertService();
export default alertService;