import Event from "common/event";
import * as _ from "lodash";
import { ActionWithDispatch } from "common/actionWithDispatch";
import PositionGroup from "domain/positionGroup";
import positionGroupService from "./positionGroupService";

export class PositionGroupsLoaded extends Event {
  constructor(public positionGroups: PositionGroup[]) {
    super(PositionGroupsLoaded.EventName);
  }

  public static EventName: string = "POSITION_GROUPS_LOADED";
}

export class PositionGroupAdded extends Event {
  constructor(public newPositionGroup: PositionGroup) {
    super(PositionGroupAdded.EventName);
  }

  public static EventName: string = "POSITION_GROUP_ADDED";
}

export function loadPositionGroups(): ActionWithDispatch<PositionGroup[]> {
  return async function(dispatch: any) {
    const positionGroups = _.orderBy(await positionGroupService
          .listPositionGroups(), m => m.name);
      return dispatch(new PositionGroupsLoaded(positionGroups).toObject());
  };
}