import { CancellationToken } from "common/ui/useAjaxCall";
import cloneClass from "common/cloneClass";
import AdminHttpClient from "./adminHttpClient";

export default class HttpService {

    private _cxt: CancellationToken = null;


    public getClient(merchantId?: string) {
        return AdminHttpClient.getProxyClient(merchantId, this._cxt);
    }

    public setCancellationToken(cxt: CancellationToken) {
        this._cxt = cxt;
    }
}

export function withCancellationToken<T extends HttpService>(service: T, cxt: CancellationToken): T {
    
    const clone = cloneClass(service);
    clone.setCancellationToken(cxt);

    return clone;
}