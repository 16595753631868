import ListItemsResponse from "./listItemsResponse";

export default interface PagedData<T> {
    data: T[];
    totalCount: number;
}

export function toPagedData<T>(response: ListItemsResponse<T>): PagedData<T> {
    return {
        data: response.results,
        totalCount: response.totalCount
    }
}