import { Alert, AlertLevel } from "domain/alert";
import { Dispatch } from "redux";
import Event from "common/event";
import alertService from "./alerts.service";


export class ErrorAlertsLoaded extends Event {
    constructor(public alerts: Alert[]) {
        super(ErrorAlertsLoaded.EventName);
    }

    public static EventName: string = "alerts/errors-loaded";
}

export async function loadErrorAlerts(dispatch: Dispatch<any>) {
    const alerts = await alertService.getAlerts(AlertLevel.Error);
    dispatch(new ErrorAlertsLoaded(alerts).toObject());
}