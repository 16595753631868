import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: ''});

const reactPlugin = new ReactPlugin();
const clickPlugin = new ClickAnalyticsPlugin();

const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
        
        autoTrackPageVisitTime: true,
        disableAjaxTracking: false,
        enableAutoRouteTracking: true,

        extensions: [reactPlugin, clickPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: {history: browserHistory },
            [clickPlugin.identifier]: { autoCapture: false }
        },

    }
});
appInsights.loadAppInsights();

export {
    reactPlugin,
    appInsights
}