import DeliveryPeriod from "domain/deliveryPeriod";
import Event from "common/event";
import deliveryPeriodService from "./deliveryPeriod.service";
import { ActionWithDispatch } from "common/actionWithDispatch";
import trackApiCall from "features/saveIndicator/trackApiCall";
import FuturesContract from "domain/futuresContract";
import Throttle from "common/decorators/throttle";
import DeliveryPeriodsBulkUploadResponse from "./uploadDeliveryPeriodsResponse";
import loggingService from "common/logging/loggingService";

export class DeliveryPeriodsLoaded extends Event {
    constructor(public deliveryPeriods: DeliveryPeriod[]) {
        super(DeliveryPeriodsLoaded.EventName);
    }

    public static EventName: string = "DELIVERY_PERIODS_LOADED";
}

export class DeliveryPeriodAdded extends Event {
    constructor(public newDeliveryPeriod: DeliveryPeriod) {
        super(DeliveryPeriodAdded.EventName);
    }

    public static EventName: string = "DELIVERY_PERIOD_ADDED";
}

export class DeliveryPeriodUpdated extends Event {
    constructor(public deliveryPeriod: DeliveryPeriod) {
        super(DeliveryPeriodUpdated.EventName);
    }

    public static EventName: string = "DELIVERY_PERIOD_UPDATED";
}

export class DeliveryPeriodDeleted extends Event {
    constructor(public deliveryPeriod: DeliveryPeriod) {
        super(DeliveryPeriodDeleted.EventName);
    }

    public static EventName: string = "DELIVERY_PERIOD_DELETED";
}

export class DeliveryPeriodDeletedWithId extends Event {
    constructor(public deliveryPeriodId: string) {
        super(DeliveryPeriodDeletedWithId.EventName);
    }

    public static EventName: string = "DELIVERY_PERIOD_DELETED_With_Id";
}

export class DeliveryPeriodsUploaded extends Event {
    constructor(public deliveryPeriodsBulkUploadResponse: DeliveryPeriodsBulkUploadResponse) {
      super(DeliveryPeriodsUploaded.EventName);
    }
  
    public static EventName: string = "DELIVERY_PERIODS_UPLOADED";
}

export class DeliveryPeriodsImported extends Event {
    constructor(public deliveryPeriodsBulkUploadResponse: DeliveryPeriodsBulkUploadResponse) {
      super(DeliveryPeriodsImported.EventName);
    }
  
    public static EventName: string = "DELIVERY_PERIODS_IMPORTED";
  }
  

export class DeliveryPeriodActions {

    @Throttle(1000, true, false)
    private static doLoad(commodityId: number, dispatch: any) {
        return deliveryPeriodService.listDeliveryPeriods(commodityId)
            .then(deliveryPeriodResponse => {
                return dispatch(new DeliveryPeriodsLoaded(deliveryPeriodResponse.deliveryPeriods).toObject());
             })
            .catch((error: any) => {
                loggingService.error('Failed to load delivery periods', error);
                throw error;
            });
    }

    public static loadDeliveryPeriods(commodityId?: number): ActionWithDispatch<DeliveryPeriod[]> {
        return async function (dispatch: any) {
            const result = await DeliveryPeriodActions.doLoad(commodityId, dispatch);
            return result.deliveryPeriods;
        }
    }

    public static createDeliveryPeriod(deliveryPeriod: DeliveryPeriod, futuresContract: FuturesContract): ActionWithDispatch<Promise<any>> {
        return trackApiCall(async dispatch => {
            let newDeliveryPeriod = await deliveryPeriodService.createDeliveryPeriod(deliveryPeriod);
            newDeliveryPeriod.contract = futuresContract;
            return (dispatch(new DeliveryPeriodAdded(newDeliveryPeriod).toObject()))
        }
        );
    }
    
    public static updateDeliveryPeriod(newValue: DeliveryPeriod, oldValue: DeliveryPeriod): ActionWithDispatch<Promise<any>> {
        return trackApiCall(dispatch => {
            dispatch(new DeliveryPeriodUpdated(newValue).toObject());
            return deliveryPeriodService
                .updateDeliveryPeriod(newValue)
                .catch((error) => {
                    dispatch(new DeliveryPeriodUpdated(oldValue).toObject());
                    throw error;
                });
        });
    }
    
    public static deleteDeliveryPeriod(deliveryPeriod: DeliveryPeriod): ActionWithDispatch<Promise<any>> {
        return trackApiCall((dispatch) =>
            deliveryPeriodService.deleteDeliveryPeriod(deliveryPeriod).then(() => dispatch(new DeliveryPeriodDeleted(deliveryPeriod).toObject()))
        );
    }

    public static uploadDeliveryPeriods(selectedFile: any): ActionWithDispatch<Promise<any>> {
        return function (dispatch: any) {
          return deliveryPeriodService
            .uploadDeliveryPeriods(selectedFile)
            .then(deliveryPeriodsBulkUploadResponse => dispatch(new DeliveryPeriodsUploaded(deliveryPeriodsBulkUploadResponse).toObject()));
        };
    }
      
    public static importDeliveryPeriods(selectedFile: any): ActionWithDispatch<Promise<any>> {
        return function (dispatch: any) {
            return deliveryPeriodService
            .importDeliveryPeriods(selectedFile)
            .then(deliveryPeriodsBulkUploadResponse => dispatch(new DeliveryPeriodsImported(deliveryPeriodsBulkUploadResponse).toObject()));
        };
    }
}