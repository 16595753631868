export default {
    /* Hidden permission, initially granted to Merchant Admin */
    ManageTrader: "ManageTrader",
    ManageOffering: "ManageOffering",
    ManageErpMapping: "ManageErpMapping",
    ManageMerchantSettings: "ManageMerchantSettings",

    /* Hidden permission, granted to all traders */
    ManageProducer: "ManageProducer",

    /* Visible permissions on Trader page */
    UpdateBid: "UpdateBid",
    ViewReport: "ViewReport",
    PlaceLimitOrder: "PlaceLimitOrder",
    BuyNow: "BuyNow",
    CancelOrder: "CancelOrder",
    PushBasis: "PushBasis",
    AutoOffer: "AutoOffer",
    AutoPosition: "AutoPosition",
    UpdateLocation: "UpdateLocation",
    ManageApiKeys: "ManageApiKeys",
    ManageWebhooks: "ManageWebhooks",
    ViewPosition: "ViewPosition",
    EditContracts: "EditContracts",
    SupervisorEditContract: "SupervisorEditContract",
    UseSnapshotQuotes: "UseSnapshotQuotes",
    SendCommunications: "SendCommunications"
}