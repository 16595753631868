import FuturesContract from "domain/futuresContract";
import futuresContractsService from "./futuresContracts.service";
import Event from "common/event";
import { ActionWithDispatch } from "common/actionWithDispatch";
import { FuturesPriceUpdate } from "features/futuresContracts/priceStreamingService";
import { Dispatch } from "redux";

export class FuturesContractsLoaded extends Event {
    constructor(public futuresContracts: FuturesContract[]) {
        super(FuturesContractsLoaded.EventName);
    }

    public static EventName: string = "FUTURES_CONTRACTS_LOADED";
}

export class FuturesContractPriceUpdated extends Event {
    constructor(public contract: FuturesPriceUpdate) {
        super(FuturesContractPriceUpdated.EventName);
    }

    public static EventName: string = "FUTURES_CONTRACT_PRICE_UPDATED";
}

export class ContractsSubscribed extends Event {
    constructor(public subscriptionId: string, public symbols: string[]) {
        super(ContractsSubscribed.EventName);
    }

    public static EventName: string = "CONTRACTS_SUBSCRIBED";
}

export class ContractsUnsubscribed extends Event {
    constructor(public subscriptionId: string) {
        super(ContractsUnsubscribed.EventName);
    }

    public static EventName: string = "CONTRACTS_UNSUBSCRIBED";
}

export function loadFuturesContracts(commodityId: number): ActionWithDispatch<Promise<FuturesContract[]>> {
    return function (dispatch: any) {
        return futuresContractsService
            .listFuturesContracts(commodityId)
            .then((futuresContracts) => dispatch(new FuturesContractsLoaded(futuresContracts).toObject()));
    };
}

export function subscribeToContracts(subscriptionId: string, symbols: string[]) {
    return (dispatch: any) => dispatch(new ContractsSubscribed(subscriptionId, symbols).toObject());
}

export function unsubscribeFromContracts(subscriptionId: string) {
    return function (dispatch: any)  {
        dispatch(new ContractsUnsubscribed(subscriptionId).toObject());
    };
}

export function requestSnapshotQuote(dispatch: Dispatch, futuresContract: FuturesContract) {
    futuresContractsService.getRealTimeSnapshot(futuresContract.id)
        .then(m => dispatch(new FuturesContractPriceUpdated(m).toObject()))
}

