import LoggedInUser from "./loggedInUser";
import Merchant from "domain/merchant";
import HttpService from "common/http/httpService";

interface Permission {
    code: string;
}

interface ListPermissionsResponse {
    results: Permission[];
    totalCount: number;
}

class LoginService extends HttpService {

    async getUserInfo(): Promise<LoggedInUser> {
        var response = await this.getClient().get<LoggedInUser>(
            "gateway/v2/users/me"
        );
    
        return response.data;
    }

    async getAdminUserInfo(): Promise<LoggedInUser> {
        var response = await this.getClient().get<LoggedInUser>(
            "gateway/v1/users/admins/me"
        );
    
        return response.data;
    }
    
    async listUserPermissions(): Promise<string[]> {
        try {
            var response = await this.getClient().get<ListPermissionsResponse>(
                "gateway/v1/users/me/permissions"
            );
        
            return response.data.results.map(m => m.code);
        }
        catch {
            return [];
        }
    }

    async setActiveMerchant(merchant: Merchant): Promise<any> {
        await this.getClient().put("gateway/v1/users/me/active-merchant", {
            id: merchant.id
        });
    }
}

const loginService = new LoginService();

export default loginService;