import axios, { AxiosInstance } from "axios";
import authTokenService from "common/authTokenService";
import { CancellationToken } from "common/ui/useAjaxCall";
import activeMerchantService from "features/merchants/activeMerchantService";

export default class HttpClient {
    
    static getProxyClient(merchantId?: string, cxt?: CancellationToken): AxiosInstance {
        return this.getClientInternal(process.env.REACT_APP_PROXY_API_BASE_URL, merchantId, cxt);
    }

    private static getClientInternal(baseUrl: string, merchantId?: string, cxt?: CancellationToken): AxiosInstance {
        const defaultHeaders = {
            Authorization: `Bearer ${authTokenService.getToken()}`,
            'x-merchant-id': merchantId ?? activeMerchantService.getActiveMerchant()
        };

        var client = axios.create({
            baseURL: baseUrl,
            headers: defaultHeaders,
            signal: cxt?.signal            
        });

        return client;
    }
}