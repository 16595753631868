import { LoggingLevel } from "./loggingLevel";

export interface ILoggingService {
    debug(message: string): void;
    info(message: string): void;
    warn(message: string): void;
    error(message: string, error?: Error): void;
}

const AppLogLevel: LoggingLevel = getLoggingLevel(process.env.REACT_APP_LOG_LEVEL ?? "Error");

class LoggingService implements ILoggingService {


    debug(message: string): void {
        if (AppLogLevel <= LoggingLevel.Debug) {
            console.debug(message);
        }
    }

    info(message: string): void {
        if (AppLogLevel <= LoggingLevel.Info) {
            console.info(message);
        }
    }

    warn(message: string): void {
        if (AppLogLevel <= LoggingLevel.Warn) {
            console.warn(message);
        }
    }

    error(message: string, error?: Error): void {
        if (AppLogLevel <= LoggingLevel.Error) {
            console.error(message);
            if (error != null) console.error(error);
        }
    }
}

function getLoggingLevel(level: string): LoggingLevel {
    switch (level.toLowerCase()) {
        case "error":
            return LoggingLevel.Error
        case "warn":
            return LoggingLevel.Warn;
        case "info":
            return LoggingLevel.Info;
        case "debug":
            return LoggingLevel.Debug;
        default:
            return LoggingLevel.Error;
    }
}

const loggingService = new LoggingService();
export default loggingService;