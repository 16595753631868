import { AccessToken, Tokens } from "@okta/okta-auth-js";

export interface IAuthTokenService {
    getToken(): string;
}

const KEY_OKTA_TOKEN: string = "okta-token-storage";

class AuthTokenService implements IAuthTokenService {
    
    getToken(): string {

        const accessToken = getAccessTokenFromStorage();

        if (accessToken?.accessToken == null) {
            return null;
        }

        if ((accessToken.expiresAt * 1000) < Date.now()) {
            return null;
        }

        return accessToken.accessToken;
    }
}

function getAccessTokenFromStorage(): AccessToken {
    const oktaToken: Tokens = JSON.parse(localStorage.getItem(KEY_OKTA_TOKEN));

    return oktaToken?.accessToken;
}

const authTokenService: IAuthTokenService = new AuthTokenService();

export default authTokenService;