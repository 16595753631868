import React from "react";
import { Link } from "react-router-dom"
import './SiteLink.scss';
import ExternalLink from "./ExternalLink";

export type SiteLinkProps = {
    children?: any;
    onClick?: () => void;
    className?: any;
    external?: boolean;
    hideExternalIcon?: boolean;
}

const UrlResolver = {

    forDashboard(): string {
        return "/";
    },

    forOfferList(offerStatus: string): string {
        return `/offers/${offerStatus.toLowerCase()}`;
    },

    forOfferDetails(offerDisplayId: string): string {
        return `/offers/${offerDisplayId}/details`;
    },
    
    forContractDetails(contractDisplayId: string): string {
        return `/contracts/${contractDisplayId}/details`;
    },
    
    forLocationSettings(): string {
        return '/settings/locations';
    },
    
    forCommoditySettings(): string {
        return '/settings/commodities';
    },

    forHedgingSettings(): string {
        return '/settings/hedgings';
    },   

    forNotifications(): string {
        return '/notifications';
    },

    forBidEditor(): string {
        return '/bid-editor';
    },

    forBidEditorCommodity(commodityId: number): string {
        return `/bid-editor/${commodityId}`;
    },

    forLongShortSettings(): string {
        return '/settings/long-short'; 
    },

    forApiKeySettings(): string {
        return '/settings/integration';
    },

    forDisclaimer(): string {
        return '/disclaimer';
    }
}

const BaseSiteLink: React.FC<SiteLinkProps & {to: string}> = props => {

    return props.external
        ? <ExternalLink url={props.to} className={props.className} hideExternalIcon={props.hideExternalIcon}>{props.children}</ExternalLink> 
        : <Link to={props.to} className={props.className}>{props.children}</Link>
}

const Dashboard = (props: SiteLinkProps) => <BaseSiteLink to={UrlResolver.forDashboard()} {...props} />
const OffersList = (props: {offerStatus: string} & SiteLinkProps) => <BaseSiteLink to={UrlResolver.forOfferList(props.offerStatus)}>{props.offerStatus} Offers</BaseSiteLink>;
const OfferDetails = (props: {offerDisplayId: string} & SiteLinkProps) => <BaseSiteLink to={UrlResolver.forOfferDetails(props.offerDisplayId)} {...props}>{props.children ? props.children : props.offerDisplayId}</BaseSiteLink>;
const ContractDetails = (props: { contractDisplayId: string } & SiteLinkProps) => <BaseSiteLink to={UrlResolver.forContractDetails(props.contractDisplayId)} {...props}>{props.children ? props.children : props.contractDisplayId}</BaseSiteLink>;
const SettingsLocations = (props: SiteLinkProps) => <BaseSiteLink to={UrlResolver.forLocationSettings()} {...props} />;
const SettingsCommodities = (props: SiteLinkProps) => <BaseSiteLink to={UrlResolver.forCommoditySettings()} {...props} />;
const SettingsHedgings = (props: SiteLinkProps) => <BaseSiteLink to={UrlResolver.forHedgingSettings()} {...props} />;
const Notifications = (props: SiteLinkProps) => <BaseSiteLink to={UrlResolver.forNotifications()} {...props} />
const BidEditor = (props: SiteLinkProps) => <BaseSiteLink to={UrlResolver.forBidEditor()} {...props} />
const BidEditorCommodity = (props: {commodityId: number} & SiteLinkProps) => <BaseSiteLink to={UrlResolver.forBidEditorCommodity(props.commodityId)} {...props} />
const SettingsLongShort = (props: SiteLinkProps) => <BaseSiteLink to={UrlResolver.forLongShortSettings()} {...props} />;
const SettingsApiKeys = (props: SiteLinkProps) => <BaseSiteLink to={UrlResolver.forApiKeySettings()} {...props} />
const Disclaimer = (props: SiteLinkProps) => <BaseSiteLink to={UrlResolver.forDisclaimer()} {...props} />


export default {
    BaseSiteLink, 
    Dashboard,
    OffersList,
    OfferDetails, 
    ContractDetails,
    SettingsLocations, 
    SettingsCommodities, 
    SettingsHedgings,
    Notifications, 
    BidEditor,
    BidEditorCommodity,
    SettingsLongShort, 
    SettingsApiKeys,
    Disclaimer,
    UrlResolver
};