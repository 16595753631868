
export function isNullOrEmpty(s: string): boolean {
    return s == null || s.trim() === '';
} 

export function compareDisplayIds(displayId1: string, displayId2: string): number {

    if (displayId1 == null && displayId2 == null) {
        return 0;
    }
    if (displayId1 == null) {
        return -1;
    }
    if (displayId2 == null) {
        return 1;
    }

    const number1 = displayId1.split("-");
    const number2 = displayId2.split("-");

    const totalLength = Math.max(number1.length, number2.length);

    for (let index = 0; index < totalLength; index++) {
        const element1 = number1[index] ? Number.parseInt(number1[index].match(/\d+/g)?.join('')) : 0;
        const element2 = number2[index] ? Number.parseInt(number2[index].match(/\d+/g)?.join('')) : 0;

        if (element1 === element2)
            continue;

        return element1 > element2 ? 1 : -1;
    }
}

const symbolRegex = /([a-z]+)([a-z])(\d+)/i;
export function compareFuturesContractSymbols(symbol1: string, symbol2: string): number {
    if (symbol1 == null && symbol2 == null) {
        return 0;
    }
    if (symbol1 == null) {
        return -1;
    }
    if (symbol2 == null) {
        return 1;
    }

    const parts1 = symbol1.match(symbolRegex);
    const parts2 = symbol2.match(symbolRegex);

    //Check root first, then year, then month
    return parts1[1].localeCompare(parts2[1]) ||
        parts1[3].localeCompare(parts2[3]) ||
        parts1[2].localeCompare(parts2[2]);
}