import { getConnectionManager, HubConnectionManager } from 'common/streaming/hubConnectionManager';
import IDispatcher from "common/dispatcher";
import positionHedgesService from './positionHedgesService';
import { PositionHedgeUpdated } from './longShort.actions';

interface OrderUpdatedEvent {
    positionHedgeId: number;
    message?: string;
}


export interface IOrderEventStreamingService {
    start(dispatcher: IDispatcher): Promise<any>;
}

export class OrderEventStreamingService implements IOrderEventStreamingService {
    private hubConnectionManager: HubConnectionManager;
    private dispatcher: IDispatcher;
    private isStarted: boolean;

    constructor() {
        this.hubConnectionManager = getConnectionManager();
    }

    public async start(dispatcher: IDispatcher): Promise<any> {
        if (this.isStarted) return;

        this.isStarted = true;

        this.dispatcher = dispatcher;
        var conn = await this.hubConnectionManager.getConnectionAsync();
        conn.on("OrderCancelFailedForPosition", ev => this.onOrderUpdated(ev));
        conn.on("OrderRejectedForPosition", ev => this.onOrderUpdated(ev));
    }

    private async onOrderUpdated(orderEvent: OrderUpdatedEvent) {
        const positionHedge = await positionHedgesService.getPositionHedge(orderEvent.positionHedgeId);
        this.dispatcher.dispatch(new PositionHedgeUpdated(positionHedge).toObject());
    }
}

const orderEventStreamingService: IOrderEventStreamingService = new OrderEventStreamingService();
export default orderEventStreamingService;