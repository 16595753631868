import ThrowsValidationError from "common/http/throwsValidationError";
import { Trader } from "./trader";
import TraderConfiguration from "./traderConfiguration";
import Throttle from "common/decorators/throttle";
import HttpService from "common/http/httpService";

class TraderService extends HttpService {
  @Throttle(1000, true)
  async listTraders(): Promise<Trader[]> {
    const response = await this.getClient().get<Trader[]>("/settings/v1/traders");
    return response.data;
  }

  async getTraderConfiguration(): Promise<TraderConfiguration> {
    const response = await this.getClient().get<TraderConfiguration>(
      "/settings/v1/configuration/traders"
    );

    return response.data;
  }

  @ThrowsValidationError()
  async createTrader(trader: Trader): Promise<Trader> {    
      const response = await this.getClient().post<Trader>("/settings/v1/traders", trader);
      return response.data;
  }

  @ThrowsValidationError()
  async updateTrader(trader: Trader): Promise<any> {
      await this.getClient().put(
        `/settings/v1/traders/${trader.userId}`,
        trader
      );
  }

  @ThrowsValidationError()
  async updateTraderCellPhone(trader: Trader): Promise<any> {
    const request = {
      cellPhone: trader.cellPhone
    };
    await this.getClient().put('/gateway/v1/users/me/cell-phone', request);
  }

  @ThrowsValidationError()
  async deleteTrader(trader: Trader): Promise<any> {
      await this.getClient().delete(`/settings/v1/traders/${trader.userId}`);
  }
}

const traderService = new TraderService();
export default traderService;
