
export enum StrategyType {
    Hedge = 1,
    Spread
}

export function getStrategyTypeName(type: StrategyType): string {
    switch (type) {
        case StrategyType.Hedge:
            return "Hedge";
        case StrategyType.Spread:
            return "Spread";
        default:
            return "";
    }
}