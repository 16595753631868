import { isNullOrEmpty } from "common/strings";

export class ProducerAccount {
    accountId: string = null;
    accountName: string = null;
    isPrimaryUser: boolean = false;
    canManage: boolean = false;
    canView: boolean = false;
    canSign: boolean = false;
}

export function getProducerAccountDisplayName(producerAccount: ProducerAccount): string {
    var displayName = ""
    if (producerAccount != null) {
        var displayName = producerAccount.accountId;
        if (!isNullOrEmpty(producerAccount.accountName))
            displayName += ` - ${producerAccount.accountName}`;
    }
    return displayName;
}