import { PositionSetting } from "domain/positionSetting";
import PositionOrder from "./positionOrder";

export class PositionBalance {
    id: number = 0;
    positionSetting: PositionSetting = null;
    physicalQuantity: number = 0;
    hedgeQuantity: number = 0;
    previousDayCarryOver: number = 0;
    workingOrders: PositionOrder[] = [];
    netQuantity: number = 0;
    netTransfer: number = 0;
};

export interface ThresholdCheck {
    exceedsThreshold: boolean;
    exceedsOppositeThreshold: boolean;
}

export function checkPositionBalanceThresholds(balance: PositionBalance): ThresholdCheck {
    const result: ThresholdCheck = { exceedsThreshold: false, exceedsOppositeThreshold: false };

    if (balance == null) return result;
    
    const setting = balance.positionSetting;

    let contractMinimum = setting.miniContractMinimum ?? setting.fullContractMinimum;

    const highThreshold = setting.targetBalance + contractMinimum;
    const lowThreshold = setting.targetBalance - contractMinimum;


    if ((setting.autoOrderWillSell && balance.netQuantity >= highThreshold) ||
        (!setting.autoOrderWillSell && balance.netQuantity <= lowThreshold)) result.exceedsThreshold = true;
    if ((setting.autoOrderWillSell && balance.netQuantity <= lowThreshold) ||
        (!setting.autoOrderWillSell && balance.netQuantity >= highThreshold)) result.exceedsOppositeThreshold = true;

    return result;
}

export function isAutoPositionEnabledForBalance(balance: PositionBalance): boolean {
    return !balance.positionSetting.isAutoPositionDisabled && !balance.positionSetting.commodity.isAutoPositionDisabled;
}