import FuturesContract from "domain/futuresContract";
import { StrategyType } from "features/positions/strategyType";
import { FuturesPriceUpdate } from "./priceStreamingService";
import HttpService from "common/http/httpService";


class FuturesContractsService extends HttpService {

    async listFuturesContracts(commodityId: number, includeMinis: boolean = false, strategy: StrategyType = StrategyType.Hedge, results: string = null): Promise<FuturesContract[]> {
        const response = await this.getClient().get<FuturesContract[]>('/settings/v1/futures-contracts', {
            params: {
                commodityId,
                includeMinis,
                strategy,
                results
            }
        });
        return response.data;
    }

    async getRealTimeSnapshot(futuresContractId: number): Promise<FuturesPriceUpdate> {
        const response =  await this.getClient().get(`/settings/v1/futures-contracts/${futuresContractId}/real-time-snapshot`);
        return response.data;
    }
}
const futuresContractsService = new FuturesContractsService();
export default futuresContractsService;
