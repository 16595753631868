import { getConnectionManager, HubConnectionManager } from 'common/streaming/hubConnectionManager';
import IDispatcher from "common/dispatcher";
import { PositionBalanceAdded, PositionBalanceRemoved, PositionBalanceUpdated } from "./longShort.actions";
import positionBalanceService from "./positionBalancesService";
import { PositionBalance } from './positionBalance';

interface PositionBalanceUpdatedEvent {
    id: number;
    balance: PositionBalance;
}

interface PositionBalanceCreatedEvent {
    id: number;
    balance: PositionBalance;
}

export interface IPositionBalanceEventStreamingService {
    start(dispatcher: IDispatcher): Promise<any>;
}

export class PositionBalanceEventStreamingService implements IPositionBalanceEventStreamingService {
    private hubConnectionManager: HubConnectionManager;
    private dispatcher: IDispatcher;
    private isStarted: boolean;

    constructor() {
        this.hubConnectionManager = getConnectionManager();
    }

    public async start(dispatcher: IDispatcher): Promise<any> {
        if (this.isStarted) return;

        this.isStarted = true;
        
        this.dispatcher = dispatcher;
        var conn = await this.hubConnectionManager.getConnectionAsync();
        conn.on("PositionBalanceCreated", ev => this.onPositionBalanceCreated(ev));
        conn.on("PositionBalanceUpdated", ev => this.onPositionBalanceUpdated(ev));
        conn.on("PositionBalanceRemoved", ev => this.onPositionBalanceRemoved(ev));
    }

    private async onPositionBalanceCreated(positionBalanceEvent: PositionBalanceCreatedEvent) {
        const positionBalance = positionBalanceEvent.balance ?? await positionBalanceService.getPositionBalance(positionBalanceEvent.id);
        this.dispatcher.dispatch(new PositionBalanceAdded(positionBalance).toObject());

    }

    private async onPositionBalanceUpdated(positionBalanceEvent: PositionBalanceUpdatedEvent) {
        const positionBalance = positionBalanceEvent.balance ?? await positionBalanceService.getPositionBalance(positionBalanceEvent.id);
        this.dispatcher.dispatch(new PositionBalanceUpdated(positionBalance).toObject());
    }

    private async onPositionBalanceRemoved(positionBalanceEvent: PositionBalanceUpdatedEvent) {
        this.dispatcher.dispatch(new PositionBalanceRemoved({...new PositionBalance(), id: positionBalanceEvent.id}).toObject());
    }
}

const positionBalanceEventStreamingService: IPositionBalanceEventStreamingService = new PositionBalanceEventStreamingService();
export default positionBalanceEventStreamingService;