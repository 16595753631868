import * as _ from "lodash";
import { ActionWithDispatch } from "common/actionWithDispatch";
import { ApiCallStarted, ApiCallSucceeded, ApiCallFailed, ApiCallCheckStatus } from "./saveIndicator.actions";
import { Dispatch } from "redux";

const throttledDispatch = _.debounce(function (dispatch, ev) {
    dispatch(ev);
}, 1000);

export default function trackApiCall<T>(action: ActionWithDispatch<Promise<T>>): ActionWithDispatch<Promise<T>> {
  return function(dispatch: any) {
    dispatch(new ApiCallStarted().toObject());
    const result = action(dispatch);
    result.then(() => {
        dispatch(new ApiCallSucceeded().toObject());
      })
      .catch(() => {
        dispatch(new ApiCallFailed().toObject());
      })
      .finally(() => {
        throttledDispatch(dispatch, new ApiCallCheckStatus().toObject())
      });
    return result;
  };
}

//TODO: once we refactor everything to use this pattern, remove the old one and rename this
export async function trackApiCallNew<T>(dispatch: Dispatch<any>, action: (dispatch: any) => Promise<T>): Promise<T> {
    dispatch(new ApiCallStarted().toObject());
    try {
      const result = action(dispatch);
      dispatch(new ApiCallSucceeded().toObject());
      return result;
    }
    catch (error) {
      dispatch(new ApiCallFailed().toObject());
      throw error;
    }
    finally {
      throttledDispatch(dispatch, new ApiCallCheckStatus().toObject())
    }
}