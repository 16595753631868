import Event from "common/event";
import loginService from "./loginService";
import LoggedInUser from "./loggedInUser";
import { ActionWithDispatch } from "common/actionWithDispatch";
import authTokenService from "common/authTokenService";
import Merchant from "domain/merchant";
import { Dispatch } from "redux";
import loggingService from "common/logging/loggingService";
import { appInsights } from "common/logging/appInsights";

export class UserLoggingIn extends Event {
    constructor() {
        super(UserLoggingIn.EventName);
    }

    public static EventName: string = "login/logging-in";
}

export class UserLoggedIn extends Event {
    constructor(public accessToken: string, public user: LoggedInUser) {
        super(UserLoggedIn.EventName);
    }

    public static EventName: string = "login/logged-in";
}

export class UserLoginFailed extends Event {
    constructor() {
        super(UserLoginFailed.EventName);
    }

    public static EventName: string = "login/login-failed";
}

export class UserLoggedOut extends Event {
    constructor() {
        super(UserLoggedOut.EventName);
    }

    public static EventName: string = "login/logged-out";
}

export class UserPermissionsLoaded extends Event {
    constructor(public permissions: string[]) {
        super(UserPermissionsLoaded.EventName);
    }

    public static EventName: string = "login/permissions-loaded";
}

export class UserInfoUpdated extends Event {
    constructor(public user: LoggedInUser) {
        super(UserInfoUpdated.EventName);
    }

    public static EventName: string = "USER_INFO_UPDATED";
}

export async function loadLoggedInUser(dispatch: Dispatch<any>): Promise<any> {
    const accessToken = authTokenService.getToken();

    try {
        const user = await loginService.getUserInfo();
        addUserInfoToTelemetry(user);
        return dispatch(new UserLoggedIn(accessToken, user).toObject())
    }
    catch (err) {
        dispatch(new UserLoginFailed().toObject());
        loggingService.error("Error during login", err as Error)
    }
}

export async function loadLoggedInAdminUser(dispatch: Dispatch<any>): Promise<any> {
    const accessToken = authTokenService.getToken();

    try {
        const user = await loginService.getAdminUserInfo();
        addUserInfoToTelemetry(user);
        return dispatch(new UserLoggedIn(accessToken, user).toObject());
    }
    catch (err) {
        dispatch(new UserLoginFailed().toObject());
        loggingService.error("Error during login", err as Error)
    }

}

export function loadUserPermissions(dispatch: Dispatch<any>): any {
    return loginService
        .listUserPermissions()
        .then(permissions => dispatch(new UserPermissionsLoaded(permissions).toObject()));
}

export function changeActiveMerchant(dispatch: Dispatch<any>, merchant: Merchant): any {
    return loginService
        .setActiveMerchant(merchant)
        .then(() => {
            window.location.reload();
        });
}

export function login(accessToken: string): ActionWithDispatch<LoggedInUser> {
    return function (dispatch: any) {
        dispatch(new UserLoggingIn().toObject())
        return loginService
            .getUserInfo()
            .then(user => {
                addUserInfoToTelemetry(user);
                return dispatch(new UserLoggedIn(accessToken, user).toObject())
            })
            .catch(err => {
                dispatch(new UserLoginFailed().toObject());
                loggingService.error("Error during login", err as Error)
            });
    };
}

export function logout(dispatch: Dispatch<any>):any {
    return dispatch(new UserLoggedOut().toObject());
}
function addUserInfoToTelemetry(user: LoggedInUser) {
    appInsights.setAuthenticatedUserContext(user.internalId, user.activeMerchant.id)
}

