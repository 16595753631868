import HttpService from "common/http/httpService";
import PositionGroup from "domain/positionGroup";

class PositionGroupService extends HttpService {
    
    async listPositionGroups(): Promise<PositionGroup[]> {
        const response = await this.getClient().get<PositionGroup[]>('/settings/v1/position-groups');
        return response.data;
    }
}

const positionGroupService = new PositionGroupService();
export default positionGroupService;
