import React from 'react';
import * as _ from "lodash";
import { ColDef } from "@ag-grid-community/all-modules";
import { ColDefBuilder } from "./columnBuilder";
import { Dictionary } from "lodash";
import ColumnConfiguration from "./columnConfiguration";
import RenderParameters from "./renderParameters";
import { Form } from "react-bootstrap";


type ColumnBuilder = {
    configuration: ColumnConfiguration,
    builder: ColDefBuilder;
}

export default class ConfigurableColumnBuilder {

    private builders: Dictionary<ColumnBuilder> = {};    

    public for(id: string, header: string, field?: string, description?: string, options?: ColDef): ColDefBuilder {
        const builder = new ColDefBuilder({
            colId: id,
            headerName: header,
            field,
            headerTooltip: description,
            ...options
        });

        this.builders[id] = {
            configuration: {
                id,
                displayName: header,
                description,
                hide: options?.hide
            },
            builder
        };
        return builder;
    }

    public buildCheckbox<T>(id: string, header: string, onChange: (data: T, isChecked: boolean) => any, field?: string, description?: string, options?: ColDef): ColDefBuilder {

        const builder = new ColDefBuilder({
            colId: id,
            headerName: header,
            field,
            headerTooltip: description,
            getQuickFilterText: () => "",
            cellRenderer: (params: RenderParameters) => {
                if (params.node.data == null) return null;
                return (
                    <Form.Check name={field} id={field + params.node.id} custom label=""
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(params.node.data, event.target.checked)}
                        checked={params.node.data[field]} />
                )
            },
            ...options
        });

        this.builders[id] = {
            configuration: {
                id,
                displayName: header,
                description,
                hide: options?.hide
            },
            builder
        };
        return builder;
    }

    public listColumns(visibleColumnIds: string[]): ColDef[] {
        return visibleColumnIds?.map(id => this.builders[id]?.builder.build())
            .filter(m => m != null);
    }

    public listColumnConfigurations(): ColumnConfiguration[] {
        return _.map(this.builders, m => m.configuration);
    }

    public listVisibleColumnConfigurations(visibleColumnIds: string[]): ColumnConfiguration[] {
        return visibleColumnIds.map(id => this.builders[id]?.configuration)
            .filter(m => m != null);
    }
}