import { getConnectionManager, HubConnectionManager } from 'common/streaming/hubConnectionManager';
import IDispatcher from "common/dispatcher";
import StoneHedgeNotification, { NotificationType } from './stoneHedgeNotification';
import { NotificationReceived } from './notifications.actions';
import notificationService from 'common/notificationService';

export interface INotificationStreamingService {
    start(dispatcher: IDispatcher): Promise<any>;
}

const autoDisappearErrors = new Set([
    "FrequencyLimitReached",
    "CommodityLimitReached",
    "MerchantSessionViolated",
    "CustomerMaxErpQuantityExceeded",
    "DeliveryPeriodNotFound"
]);

export class NotificationStreamingService implements INotificationStreamingService {
    private hubConnectionManager: HubConnectionManager;
    private dispatcher: IDispatcher;
    private isStarted: boolean;

    constructor() {
        this.hubConnectionManager = getConnectionManager();
    }

    public async start(dispatcher: IDispatcher): Promise<any> {
        if (this.isStarted) return;

        this.isStarted = true;
        
        this.dispatcher = dispatcher;
        var conn = await this.hubConnectionManager.getConnectionAsync();
        conn.on("NotificationAdded", ev => this.onNotificationAdded(ev));
    }


    private async onNotificationAdded(notification: StoneHedgeNotification) {
        this.dispatcher.dispatch(new NotificationReceived(notification).toObject());

        switch (notification.level) {
            case NotificationType.Success:
                notificationService.success(notification.message, notification.title);
            break;
            case NotificationType.Error:
                notificationService.error(notification.message, notification.title, !autoDisappearErrors.has(notification.type));
            break;
            case NotificationType.Warning:
                notificationService.warning(notification.message, notification.title);
            break;
        }
    }
}

const notificationStreamingService: INotificationStreamingService = new NotificationStreamingService();
export default notificationStreamingService;