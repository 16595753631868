import { createStore, applyMiddleware, compose, Store, AnyAction, combineReducers } from "redux";

import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunkMiddleware from "redux-thunk";
import { IModule, IModuleBuilder } from "./IModule";
import IAppStore from './IAppStore';
import { RouteProps, RouteComponentProps } from "react-router-dom";
import { ReducerBase } from "common/reducer";
import signalRMiddleware from "common/streaming/signalRMiddleware";
import React from "react";

export interface AppRouteProps extends RouteProps {
    requirePermission?: string;
    requiresAuthentication?: boolean;
}

export default class ModuleManager {
    
    private rootReducer: any = null;
    routes: AppRouteProps[] = [];

    loadModules(modules: IModule[]) {
        
        const builder = new ModuleBuilder();

        for(let i in modules) {
            const mod = modules[i];

            mod.build(builder);
        }

        this.rootReducer = combineReducers(builder.reducers);
        this.routes = builder.routes;
    }

    getStore(initialState?: any): Store<any, AnyAction> & {
        dispatch: any;
    } {
        const composeEnhancers =
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
    
        return createStore(
            this.rootReducer,
            initialState,
            process.env.REACT_APP_USE_REDUX_INVARIANT_CHECK
                ? composeEnhancers(applyMiddleware(thunkMiddleware, reduxImmutableStateInvariant(), signalRMiddleware))
                : composeEnhancers(applyMiddleware(thunkMiddleware, signalRMiddleware))
        );
    }
}

class ModuleBuilder implements IModuleBuilder {
    routes: AppRouteProps[] = [];
    reducers: any = {};

    addReducer<T>(name: keyof IAppStore, reducer: ReducerBase<T>): IModuleBuilder {        
        this.reducers[name] = (state: T, ev: any) => reducer.handleEvent(state, ev);

        return this;
    }
    
    addRoute(
        path: string,
        component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>, 
        exact?: boolean): IModuleBuilder {
        this.routes.push({
            path,
            exact,
            component
        });
        return this;
    }

    addPermissionedRoute(
        path: string,
        permissionName: string,
        component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>, 
        exact?: boolean): IModuleBuilder {
        this.routes.push({
            path,
            exact,
            component,
            requirePermission: permissionName
        });
        return this;
    }

    addAuthenticatedRoute(
        path: string,
        component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>,
        exact?: boolean): IModuleBuilder {
        this.routes.push({
            path,
            exact,
            component,
            requiresAuthentication: true
        });
        return this;
    }
}