import { PositionBalance } from "./positionBalance";
import PositionBalanceHistory, { PositionBalanceHistoryFilter } from "./positionBalanceHistory";
import HttpService from "common/http/httpService";

class PositionBalancesService extends HttpService {

    async listPositionBalances(): Promise<PositionBalance[]> {
        const response = await this.getClient().get<PositionBalance[]>('/offers/v1/position-balances');
        return response.data;
    }

    async listPositionBalancesHistory(filter: PositionBalanceHistoryFilter): Promise<PositionBalanceHistory[]> {
        const response = await this.getClient().get<PositionBalanceHistory[]>('/offers/v1/position-balances/history', {
            params: {
                commodityId: filter.commodityId,
                startDate: filter.startDate,
            }
        });
        return response.data;
    }

    async getPositionBalance(id: number): Promise<PositionBalance> {
        const response = await this.getClient().get<PositionBalance>(`/offers/v1/position-balances/${id}`);
        return response.data;
    }

    async transferPositionBalance(id: number, positionBalanceDestinationId: number): Promise<any> {
        const transferPositionRequest = {
            positionBalanceDestinationId: positionBalanceDestinationId
        };
        const response = await this.getClient().post(`/offers/v1/position-balances/${id}/transfer`, transferPositionRequest);
        return response.data;
    }
}



const positionBalanceService = new PositionBalancesService();
export default positionBalanceService;
