import { getConnectionManager, HubConnectionManager } from 'common/streaming/hubConnectionManager';
import IDispatcher from "common/dispatcher";
import { UserPermissionsLoaded, UserInfoUpdated } from "features/login/login.action";
import loginService from 'features/login/loginService';
import alertService from 'features/alerts/alerts.service';
import { AlertLevel } from 'domain/alert';
import { ErrorAlertsLoaded } from 'features/alerts/alerts.actions';
import systemConfigService from 'features/systemConfig/systemConfigService';
import { SystemConfigLoaded } from 'features/systemConfig/systemConfig.actions';
import { MyCommodityUpdatedFromStream, StreamingCommodityUpdate } from 'features/settings/commodities/commodities.actions';

interface PermissionUpdatedEvent {
    userId: string;
    message?: string;
}

export type MonitoringEventListener = () => void;

export interface IMerchantEventStreamingService {
    start(dispatcher: IDispatcher): Promise<any>;
    listenToMonitoringEvents(listener: MonitoringEventListener): void;
    stopListening(): void;
}


export class MerchantEventStreamingService implements IMerchantEventStreamingService {
    private hubConnectionManager: HubConnectionManager;
    private dispatcher: IDispatcher;
    private isStarted: boolean;

    private monitoringEventListener: MonitoringEventListener = null;

    constructor() {
        this.hubConnectionManager = getConnectionManager();
    }

    public async start(dispatcher: IDispatcher): Promise<any> {
        if (this.isStarted) return;

        this.isStarted = true;

        this.dispatcher = dispatcher;
        const conn = await this.hubConnectionManager.getConnectionAsync();
        conn.on("PermissionUpdated", ev => this.onPermissionUpdated(ev));
        conn.on("AutoHedgingConfigUpdated", ev => this.onAutoHedgingConfigUpdated(ev));
        conn.on("AlertsUpdated", () => this.onAlertsUpdated());
        conn.on("MonitoringEventReceived", () => this.onMonitoringEventReceived());
        conn.on("MerchantSettingUpdated", () => this.onMerchantSettingUpdated());
        conn.on("CommodityUpdated", c => this.onCommodityUpdated(c));
    }

    public async listenToMonitoringEvents(listener: MonitoringEventListener) {
        this.monitoringEventListener = listener;
        const conn = await this.hubConnectionManager.getConnectionAsync();
        conn.send("ListenToMonitoringEvents");
    }

    public stopListening() {
        this.monitoringEventListener = null;
    }

    private async onPermissionUpdated(event: PermissionUpdatedEvent) {
        const [permissions, userInfo] = await Promise.all([loginService.listUserPermissions(), loginService.getUserInfo()]);
        this.dispatcher.dispatch(new UserPermissionsLoaded(permissions).toObject());
        this.dispatcher.dispatch(new UserInfoUpdated(userInfo).toObject());
    }

    private async onAutoHedgingConfigUpdated(event: any) {
        const [userInfo, systemConfig] = await Promise.all([loginService.getUserInfo(), systemConfigService.getSystemConfig()]);
        this.dispatcher.dispatch(new UserInfoUpdated(userInfo).toObject());
        this.dispatcher.dispatch(new SystemConfigLoaded(systemConfig).toObject());
    }

    private async onAlertsUpdated() {
        const alerts = await alertService.getAlerts(AlertLevel.Error);
        this.dispatcher.dispatch(new ErrorAlertsLoaded(alerts).toObject());
    }

    private async onMerchantSettingUpdated() {
        const userInfo = await loginService.getUserInfo();
        this.dispatcher.dispatch(new UserInfoUpdated(userInfo).toObject());
    }

    private async onCommodityUpdated(update: StreamingCommodityUpdate) {
        this.dispatcher.dispatch(new MyCommodityUpdatedFromStream(update).toObject());
    }

    private onMonitoringEventReceived(): void {
        if (this.monitoringEventListener == null) return;

        this.monitoringEventListener();
    }
}

const merchantEventStreamingService: IMerchantEventStreamingService = new MerchantEventStreamingService();
export default merchantEventStreamingService;