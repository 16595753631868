import { PositionSetting } from "domain/positionSetting";
import positionSettingService from "features/settings/longshort/positionSettingsService";
import Event from "common/event";
import { ActionWithDispatch } from "common/actionWithDispatch";
import PositionSettingConfiguration from "features/settings/longshort/positionSettingConfiguration";
import trackApiCall from "features/saveIndicator/trackApiCall";
import { PositionBalance } from "./positionBalance";
import positionBalanceService from "./positionBalancesService";
import { PositionHedge } from "./positionHedge";
import positionHedgesService from "./positionHedgesService";

export class PositionSettingsLoaded extends Event {
  constructor(public positionSettings: PositionSetting[]) {
    super(PositionSettingsLoaded.EventName);
  }

  public static EventName: string = "positionSettings/loaded";
}

export class PositionSettingConfigurationLoaded extends Event {
  constructor(public positionSettingconfiguration: PositionSettingConfiguration) {
    super(PositionSettingConfigurationLoaded.EventName);
  }

  public static EventName: string = "positionConfigurations/loaded";
}

export class PositionSettingAdded extends Event {
  constructor(public position: PositionSetting) {
    super(PositionSettingAdded.EventName);
  }

  public static EventName: string = "positionSettings/added";
}

export class PositionSettingUpdated extends Event {
  constructor(public position: PositionSetting) {
    super(PositionSettingUpdated.EventName);
  }

  public static EventName: string = "positionSettings/updated";
}

export class PositionSettingDeleted extends Event {
	constructor(public positionSetting: PositionSetting) {
		super(PositionSettingDeleted.EventName);
	}

  public static EventName: string = "positionSettings/deleted";
}

export class PositionBalancesLoaded extends Event {
  constructor(public positionBalances: PositionBalance[]) {
    super(PositionBalancesLoaded.EventName);
  }

  public static EventName: string = "positionBalances/loaded";
}

export class PositionBalanceAdded extends Event {
  constructor(public positionBalance: PositionBalance) {
    super(PositionBalanceAdded.EventName)
  }

  public static EventName: string = "positionBalances/added";
}

export class PositionBalanceUpdated extends Event {
  constructor(public positionBalance: PositionBalance) {
    super(PositionBalanceUpdated.EventName)
  }

  public static EventName: string = "positionBalances/updated";
}

export class PositionBalanceRemoved extends Event {
	constructor(public positionBalance: PositionBalance) {
    super(PositionBalanceRemoved.EventName)
  }

  public static EventName: string = "positionBalances/removed";
}

export class PositionHedgeAdded extends Event {
  constructor(public positionHedge: PositionHedge) {
    super(PositionHedgeAdded.EventName);
  }

  public static EventName: string = "positionHedges/added";
}

export class PositionHedgeUpdated extends Event {
  constructor(public positionHedge: PositionHedge) {
    super(PositionHedgeUpdated.EventName);
  }

  public static EventName: string = "positionHedges/updated";
}

export function loadPositionSettings(): ActionWithDispatch<PositionSetting[]> {
  return function(dispatch: any) {
    return positionSettingService
      .listPositionSettings()
      .then(positionSettings =>
        dispatch(new PositionSettingsLoaded(positionSettings).toObject())
      );
  };
}

export function loadPositionSettingConfiguration(): ActionWithDispatch<PositionSettingConfiguration> {
  return function(dispatch: any) {
    return positionSettingService
      .getPositionSettingConfiguration()
      .then(data => dispatch(new PositionSettingConfigurationLoaded(data).toObject()));
  };
}

export function createPositionSetting(position: PositionSetting): ActionWithDispatch<Promise<any>> {
  return trackApiCall(dispatch => 
    positionSettingService.addPositionSetting(position)
      .then((newPosition) => dispatch(new PositionSettingAdded(newPosition).toObject()))
  );
}

export function updatePositionSetting(newValue: PositionSetting, oldValue: PositionSetting): ActionWithDispatch<Promise<any>> {
  return trackApiCall(dispatch => {
    dispatch(new PositionSettingUpdated(newValue).toObject());
    return positionSettingService
      .updatePositionSetting(newValue)
      .catch((error) => {
        dispatch(new PositionSettingUpdated(oldValue).toObject());
        throw error;
      });
  });
}

export function deletePositionSetting(position: PositionSetting): ActionWithDispatch<Promise<any>> {
	return trackApiCall((dispatch) =>
  positionSettingService.deletePositionSetting(position).then(() => 
           dispatch(new PositionSettingDeleted(position).toObject()))
	);
}

export function loadPositionBalances(): ActionWithDispatch<PositionBalance[]> {
  return function (dispatch: any) {
    return positionBalanceService
      .listPositionBalances()
      .then(positionBalances =>
        dispatch(new PositionBalancesLoaded(positionBalances).toObject())
      );
  };
}

export function createPositionHedge(positionHedge: PositionHedge, positionBalanceId: number): ActionWithDispatch<Promise<any>> {
  return trackApiCall(dispatch => 
    positionHedgesService.addPositionHedge(positionHedge, positionBalanceId)
      .then((newPositionHedge) => dispatch(new PositionHedgeAdded(newPositionHedge).toObject()))
  );
}

export function updatePositionHedge(newValue: PositionHedge, oldValue: PositionHedge): ActionWithDispatch<Promise<any>> {
  return trackApiCall(dispatch => {
    dispatch(new PositionHedgeUpdated(newValue).toObject());
    return positionHedgesService
      .updatePositionHedge(newValue)
      .catch((error) => {
        dispatch(new PositionHedgeUpdated(oldValue).toObject());
        throw error;
      });
  });
}

export function cancelPositionHedge(positionHedge: PositionHedge): ActionWithDispatch<Promise<any>> {
  return trackApiCall(dispatch =>
    positionHedgesService.cancelPositionHedge(positionHedge)
          .then((updatedPositionHedge) => dispatch(new PositionHedgeUpdated(updatedPositionHedge).toObject()))
  );
}

