import Merchant from "domain/merchant";

class ActiveMerchantService {

    private activeMerchantId: string = '';

    getActiveMerchant(): string {
        return this.activeMerchantId;
    }
    setActiveMerchant(merchant: Merchant): void {
        this.activeMerchantId = merchant.id;
    }
}

const activeMerchantService = new ActiveMerchantService();
export default activeMerchantService;