import React from 'react';
import { Security } from '@okta/okta-react';
import { AuthState, OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as LoginActions from 'features/login/login.action';
import notificationService from 'common/notificationService';

const OktaApp: React.FC<any> = props => {

    const history = useHistory();
    const dispatch = useDispatch();


    const oktaAuth = new OktaAuth({
        clientId: process.env.REACT_APP_IDP_CLIENT_ID,
        issuer: process.env.REACT_APP_IDP_AUTHORITY,
        pkce: true,
        scopes: ['openid', 'offline_access'] //Need offline_access to get the refresh token
    });

    oktaAuth.start();

    oktaAuth.authStateManager.subscribe(handleAuthStateUpdate);

    oktaAuth.tokenManager.on("error", () => {
        notificationService.error("Your session has timed out", "", true);
        LoginActions.logout(dispatch);
    });
    
    function handleAuthStateUpdate(state: AuthState) {
        const prevState = oktaAuth.authStateManager.getPreviousAuthState();

        if (prevState == null) return;

        if (prevState.isAuthenticated && !state.isAuthenticated) {
            notificationService.error("Your session has timed out", "", true);
            LoginActions.logout(dispatch);
        }
        else if (prevState.isAuthenticated && state.isAuthenticated &&
            prevState.accessToken.accessToken !== state.accessToken.accessToken) {
            LoginActions.login(state.accessToken.accessToken)(dispatch);
        }
    }

    const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
        history.replace(originalUri ? toRelativeUrl(originalUri, window.location.origin) : window.location.origin);
    };

    return <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        {props.children}
    </Security>;
}

export default OktaApp;