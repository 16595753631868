import { Dictionary } from "lodash";

export default interface StoneHedgeNotification {
    id: string;
    type: string;
    title: string;
    message: string;
    level: NotificationType;
    link?: string;
    data : Dictionary<String>,
    isRead: boolean;
    createdDate: Date;
}

export enum NotificationType {
    DisplayOnly = 0,
    Success = 1,
    Error = 2,
    Warning 
}

export class NotificationTypes {
    public static CustomCommunication: string = "CustomCommunication";
}