import { formatPricingValue } from "common/formatters";
import Commodity from "./commodity";
import { Unit, UnitConversionRate } from "./unit";
import * as NP from "number-precision";

class UnitHelper {
    
    public getQuantityTextWithUnits(quantity: number, commodity: Commodity, unitsConversionRates: UnitConversionRate[]): string {
        const baseQuantityText = this.getQuantityWithUnits(quantity, commodity.baseUnit);
        if(commodity.baseUnit.id === commodity.localUnit.id) return baseQuantityText;
       
        const localQuantity = this.getLocalQuantity(quantity, commodity, unitsConversionRates);
        return localQuantity != null ? `${baseQuantityText} / ${this.getQuantityWithUnits(localQuantity, commodity.localUnit)}` : baseQuantityText;
    }

    public getLocalQuantityTextWithUnits(quantity: number, commodity: Commodity, unitsConversionRates: UnitConversionRate[]): string {
        const localQuantity = this.getLocalQuantity(quantity, commodity, unitsConversionRates);
        return localQuantity != null ? this.getQuantityWithUnits(localQuantity, commodity.localUnit) : null;
    }

    public getLocalQuantity(quantity: number, commodity: Commodity, unitsConversionRates: UnitConversionRate[]): number {
        return this.getConvertedQuantity(quantity, commodity.id, commodity.baseUnit.id, commodity.localUnit.id, unitsConversionRates);
    }

    public getBaseQuantity(quantity: number, commodity: Commodity, unitsConversionRates: UnitConversionRate[]): number {
        return this.getConvertedQuantity(quantity, commodity.id, commodity.localUnit.id, commodity.baseUnit.id, unitsConversionRates);
    }

    private getConvertedQuantity(quantity: number, commodityId: number, fromUnitId: number, toUnitId: number, unitsConversionRates: UnitConversionRate[]): number {
        if(quantity == null || quantity === 0) return quantity;

        const conversionRate =  unitsConversionRates?.find(c => c.commodityId === commodityId &&
            c.fromUnitId === fromUnitId && c.toUnitId === toUnitId)?.conversionRate;

        if(conversionRate == null) return null;

        return Number((quantity * conversionRate).toFixed(2));
    }

    private getQuantityWithUnits(quantity: number, unit: Unit): string {
        return `${quantity.toLocaleString()} ${unit.abbreviation}`;
    }

    public getLocalPriceText(price: number, commodity: Commodity, unitsConversionRates: UnitConversionRate[]): string {
        const priceText = formatPricingValue(price);
        const localPrice = this.getLocalPrice(price, commodity, unitsConversionRates);
        return `${priceText} ${price != null && localPrice != null ? `/ ${formatPricingValue(localPrice)}` : ""}`;
    }

    public getLocalPrice(price: number, commodity: Commodity, unitsConversionRates: UnitConversionRate[]): number {
        return this.getConvertedPrice(price, commodity.id, commodity.baseUnit.id, commodity.localUnit.id, unitsConversionRates);
    }

    public getBasePrice(price: number, commodity: Commodity, unitsConversionRates: UnitConversionRate[]): number {
        return this.getConvertedPrice(price, commodity.id, commodity.localUnit.id, commodity.baseUnit.id, unitsConversionRates);
    }

    public getPriceText(price: number, showLocalUOM: boolean, currencyCode: string, commodity: Commodity, unitsConversionRates: UnitConversionRate[]): string {
        return `${formatPricingValue(price)} ${currencyCode} ${showLocalUOM ? `/ ${this.getLocalPrice(price, commodity, unitsConversionRates)} ${currencyCode}` : ""}`;
    }

    private getConvertedPrice(price: number, commodityId: number, fromUnitId: number, toUnitId: number, unitsConversionRates: UnitConversionRate[]): number {
        if(price == null || price === 0) return price;

        const conversionRate =  unitsConversionRates?.find(c => c.commodityId === commodityId &&
            c.fromUnitId === fromUnitId && c.toUnitId === toUnitId)?.conversionRate;

        if(conversionRate == null) return null;

        return Number(NP.divide(price, conversionRate).toFixed(4));
    }
}

const unitHelper = new UnitHelper()

export default unitHelper;