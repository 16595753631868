import Offer from "domain/offer";
import OfferHistory from "domain/offerHistory";
import ListItemsResponse from "common/listItemsResponse";
import { OfferStatus } from "domain/offerStatus";
import PhysicalLocation from "domain/physicalLocation";
import Commodity from "domain/commodity";
import { Trader } from "features/settings/traders/trader";
import OfferColumnConfigurations from "./offerColumnConfigurations";
import { OfferTypeEnum } from "domain/offerType";
import { DeliveryMethod } from "domain/deliveryMethod";
import { formatUTCDate } from "common/formatters";
import ThrowsValidationError from "common/http/throwsValidationError";
import ReturnsNullOn404 from "common/http/returnsNullOn404";
import { OfferAction } from "./offerAction";
import { PaymentOption } from "domain/paymentOption";
import { OrderType } from "domain/orderType";
import PatchOperation, { OperationType } from "common/http/patchOperation";
import { Producer } from "features/settings/producers/producer";
import { FeeThin } from "domain/fee";
import { CustomFieldThin } from "domain/customField";
import Throttle from "common/decorators/throttle";
import HttpService from "common/http/httpService";
import { TasFallbackOption } from "domain/tasFallbackOption";
import ThrowsAccessDenied from "common/http/throwsAccessDenied";

class OfferService extends HttpService {

    @Throttle(1000, true)
    @ReturnsNullOn404()
    async getColumnConfigurations(): Promise<OfferColumnConfigurations> {
        const response = await this.getClient().get<OfferColumnConfigurations>('/settings/v1/user-preferences/offer-columns');
        return response.data;
    }

    @ThrowsValidationError()
    async updateColumnConfigurations(config: OfferColumnConfigurations): Promise<any> {
        await this.getClient().put('/settings/v1/user-preferences/offer-columns', config);
    }
    
    async listOffers(filter?: ListOffersFilter): Promise<ListItemsResponse<Offer>> {
        const response = await this.getClient().get<ListItemsResponse<Offer>>('/offers/v1/offers', {
            params: filter == null
                ? null
                : {
                    pageSize: filter.pageSize,
                    offset: filter.offset,
                    status: filter.status,
                    excludeStatuses: filter.excludeStatuses?.join(','),
                    type: filter.type,
                    locations: filter.locations?.map(l => l.id).join(','),
                    commodities: filter.commodities?.map(c => c.id).join(','),
                    traders: filter.traders?.map(t => t.userId).join(','),
                    customers: filter.customers?.map(m => m.id).join(','),
                    customerAccount: filter.customerAccount,
                    deliveryPeriodId: filter.deliveryPeriodId,
                    customerWillSell: filter.willSell,
                    filledStartDate: filter.filledStartDate,
                    filledEndDate: filter.filledEndDate
                }
        });
        return response.data;
    }

    @ReturnsNullOn404()
    @ThrowsAccessDenied()
    async getOffer(offerId: number): Promise<Offer> {
        const response = await this.getClient().get<Offer>(`/offers/v1/offers/${offerId}`);
        return response.data;
    }

    @ReturnsNullOn404()
    @ThrowsAccessDenied()
    async getOfferByDisplayId(offerDisplayId: string): Promise<Offer> {
        const response = await this.getClient().get<Offer>(`/offers/v1/offers/by-display-id/${offerDisplayId}`);
        return response.data;
    }

    @ThrowsValidationError()
    async createOffer(offer: Offer, placeHedgeOrder: boolean): Promise<Offer> {
        const request = this.GetCreateOfferRequest(offer, placeHedgeOrder);
        const response = await this.getClient().post<Offer>("/offers/v1/offers", request);
        return response.data;
    }

    @ThrowsValidationError()
    async priceBalances(offer: Offer, placeHedgeOrder: boolean): Promise<ListItemsResponse<Offer>>
    {
        const request = this.GetCreateOfferRequest(offer, placeHedgeOrder);
        const response = await this.getClient().post<ListItemsResponse<Offer>>("/offers/v1/offers/price-balances", request);
        return response.data;
    }

    private GetCreateOfferRequest(offer: Offer, placeHedgeOrder: boolean): CreateOfferRequest {
        const request: CreateOfferRequest = {
            sourceContractId: offer.sourceContractId,
            deliveryPeriodId: offer.deliveryPeriod.id,
            deliveryPeriodStartDate: formatUTCDate(offer.deliveryPeriodStartDate, "yyyy-MM-DD"),
            deliveryPeriodEndDate: formatUTCDate(offer.deliveryPeriodEndDate, "yyyy-MM-DD"),
            futuresContractId: offer.futuresContract.id,
            locationId: offer.location?.id,
            contractLocationId: offer.contractLocation?.id,
            regionId: offer.region?.id,
            producerId: offer.producer.id,
            producerAccount: offer.customerAccount?.accountId,
            customerWillSell: offer.customerWillSell,
            type: offer.type,
            traderId: offer.trader?.userId, //Customers don't pick a trader
            deliveryMethod: offer.deliveryMethod,
            freightFee: offer.freightFee,
            htaFee: offer.htaFee,
            deliveryTerms: offer.deliveryTerms,
            customerContractId: offer.customerContractId,
            orderType: offer.orderType,
            cashPrice: offer.cashPrice,
            basis: offer.basis,
            isBasisFixed: offer.isBasisFixed,
            quantity: offer.quantity,
            expirationDate: formatUTCDate(offer.expirationDate, "yyyy-MM-DD"),
            paymentOption: offer.paymentOption,
            deferredPayDate: offer.deferredPayDate ? formatUTCDate(offer.deferredPayDate, "yyyy-MM-DD") : null,
            interestStartDate: offer.interestStartDate ? formatUTCDate(offer.interestStartDate, "yyyy-MM-DD") : null,
            setInterestStartDateOnDateOfFill: offer.setInterestStartDateOnDateOfFill,
            interestRate: offer.interestRate,
            comments: offer.comments,
            placeHedgeOrder: placeHedgeOrder,
            buyAtTarget: offer.buyAtTarget,
            fcmAccountId: offer.fcmAccount?.id,
            useMinis: offer.useMini,
            numberOfContracts: offer.numberOfContracts,
            notifyCustomer: offer.notifyCustomer,
            isPriceAtOpenEnabled: offer.isPriceAtOpenEnabled,
            fees: offer.fees,
            customFields: offer.customFields,
            isPriceLocked: offer.isPriceLocked,
            snapshotPrice: offer.snapshotPrice,
            snapshotTime: offer.snapshotTime,
            disableErpSync: offer.disableErpSync,
            tasFallbackOption: offer.tasFallbackOption
        };

        return request;
    }

    @ThrowsValidationError()
    async updateOffer(offer: Offer, placeHedgeOrder: boolean): Promise<Offer> {

        const request: UpdateOfferRequest = {
            sourceContractId: offer.type === OfferTypeEnum.FixFuturesOnBasis ? offer.sourceContractId : null,
            deliveryPeriodId: offer.deliveryPeriod.id,
            deliveryPeriodStartDate: formatUTCDate(offer.deliveryPeriodStartDate, "yyyy-MM-DD"),
            deliveryPeriodEndDate: formatUTCDate(offer.deliveryPeriodEndDate, "yyyy-MM-DD"),
            futuresContractId: offer.futuresContract.id,
            locationId: offer.location?.id,
            regionId: offer.region?.id,
            producerAccount: offer.customerAccount?.accountId,
            traderId: offer.trader.userId,
            deliveryMethod: offer.deliveryMethod,
            freightFee: offer.freightFee,
            htaFee: offer.htaFee,
            deliveryTerms: offer.deliveryTerms,
            customerContractId: offer.customerContractId,
            orderType: offer.orderType,
            cashPrice: offer.cashPrice,
            basis: offer.basis,
            isBasisFixed: offer.isBasisFixed,
            quantity: offer.quantity,
            expirationDate: formatUTCDate(offer.expirationDate, "yyyy-MM-DD"),
            paymentOption: offer.paymentOption,
            deferredPayDate: offer.deferredPayDate ? formatUTCDate(offer.deferredPayDate, "yyyy-MM-DD") : null,
            interestStartDate: offer.interestStartDate ? formatUTCDate(offer.interestStartDate, "yyyy-MM-DD") : null,
            setInterestStartDateOnDateOfFill: offer.setInterestStartDateOnDateOfFill,
            interestRate: offer.interestRate,
            comments: offer.comments,
            placeHedgeOrder: placeHedgeOrder,
            fcmAccountId: offer.fcmAccount == null ? null : offer.fcmAccount.id,
            useMinis: offer.useMini,
            numberOfContracts: offer.numberOfContracts,
            isPriceAtOpenEnabled: offer.isPriceAtOpenEnabled,
            fees: offer.fees,
            customFields: offer.customFields,
            tasFallbackOption: offer.tasFallbackOption
        };

        const response = await this.getClient().put<Offer>(`/offers/v1/offers/${offer.id}`, request);
        return response.data;
    }

    @ThrowsValidationError()
    async patchOffer(offerId: number, request: PatchOfferRequest): Promise<Offer> {

        const operations: PatchOperation[] = [];

        if (request.deliveryTerms !== undefined) {
            operations.push(new PatchOperation(OperationType.Replace, "/deliveryTerms", request.deliveryTerms));
        }

        const response = await this.getClient().patch<Offer>(`/offers/v1/offers/${offerId}`, operations);
        
        return response.data;
    }

    async listOfferHistory(offerId: number): Promise<ListItemsResponse<OfferHistory>> {
        const response = await this.getClient().get<ListItemsResponse<OfferHistory>>(`/offers/v1/offers/${offerId}/history`);
        return response.data;
    }

    
    @ThrowsValidationError()
    async approvePendingOffer(offer: Offer): Promise<Offer> {
        return await this.performOfferAction(offer, OfferAction.approvePendingOffer);
    }
    
    @ThrowsValidationError()
    async placeLimitOrder(offer: Offer): Promise<Offer> {
        return await this.performOfferAction(offer, OfferAction.placeLimitOrder);
    }
    
    @ThrowsValidationError()
    async cancelOffer(offer: Offer): Promise<Offer> {
        return await this.performOfferAction(offer, OfferAction.cancelOffer);
    }
    
    @ThrowsValidationError()
    async cancelHedgeOrder(offer: Offer): Promise<Offer> {
        return await this.performOfferAction(offer, OfferAction.cancelOrder);
    }
    
    @ThrowsValidationError()
    async buyAtTargetPrice(offer: Offer): Promise<Offer> {
        return await this.performOfferAction(offer, OfferAction.buyAtTargetPrice);
    }

    @ThrowsValidationError()
    async placeMarketOrder(offer: Offer): Promise<Offer> {
        return await this.performOfferAction(offer, OfferAction.placeMarketOrder);
    }

    @ThrowsValidationError()
    async switchToMarketOffer(offer: Offer): Promise<Offer> {
        return await this.performOfferAction(offer, OfferAction.switchToMarketOffer);
    }

    @ThrowsValidationError()
    async moveUnfilledToNewOffer(offer: Offer, cancelNewOffer: boolean): Promise<Offer> {
        return await this.performOfferAction(offer, cancelNewOffer
            ? OfferAction.moveUnfilledToNewOfferAndCancel
            : OfferAction.moveUnfilledToNewOffer);
    }

    @ThrowsValidationError()
    async dismissAlert(offer: Offer): Promise<Offer> {
        return await this.performOfferAction(offer, OfferAction.dismissAlert);
    }

    @ThrowsValidationError()
    async rollOffers(request: RollOffersRequest): Promise<any> {
        
        const response = await this.getClient().post('/offers/v1/offers/roll', request);
        
        return response.data;
    }

    private async performOfferAction(offer: Offer, action: OfferAction) {
        const request = {
            actionType: action,
            fcmAccountId: offer.fcmAccount?.id,
            useMinis: offer.useMini,
            numberOfContracts: offer.numberOfContracts
        }
        const response = await this.getClient().put<Offer>(`/offers/v1/offers/${offer.id}/action`, request);
        return response.data;
    }
}

interface OfferRequestBase {
    sourceContractId?: number;
    deliveryPeriodId: string;
    deliveryPeriodStartDate: string;
    deliveryPeriodEndDate: string;
    futuresContractId: number;
    locationId: string;
    regionId?: string;
    producerAccount: string;
    traderId: string;
    deliveryMethod: DeliveryMethod;
    freightFee?: number;
    htaFee?: number;
    deliveryTerms?: string;
    customerContractId?: string;
    orderType: OrderType;
    cashPrice: number;
    basis: number;
    isBasisFixed: boolean;
    quantity: number;
    expirationDate: string;
    paymentOption: PaymentOption;
    deferredPayDate: string;
    interestStartDate?: string;
    setInterestStartDateOnDateOfFill?: boolean;
    interestRate?: number;
    comments: string;
    placeHedgeOrder: boolean;
    fcmAccountId: number;
    useMinis: boolean;
    numberOfContracts: number;
    isPriceAtOpenEnabled: boolean;
    fees: FeeThin[];
    customFields: CustomFieldThin[];
    tasFallbackOption?: TasFallbackOption;
}

interface CreateOfferRequest extends OfferRequestBase {
    producerId: string;
    customerWillSell: boolean;
    type: OfferTypeEnum;
    buyAtTarget: boolean;
    notifyCustomer: boolean;
    contractLocationId: string;
    priceAllLocations?: boolean;
    isPriceLocked?: boolean;
    snapshotPrice?: number;
    snapshotTime?: Date;
    disableErpSync: boolean;
}

interface UpdateOfferRequest extends OfferRequestBase {

}

interface PatchOfferRequest {
    deliveryTerms?: string;
}

export type ListOffersFilter = {
    status?: OfferStatus;
    excludeStatuses?: OfferStatus[];
    type?: OfferTypeEnum;
    locations?: PhysicalLocation[];
    commodities?: Commodity[];
    willSell?: boolean;
    traders?: Trader[];
    customers?: Producer[];
    customerAccount?: string;
    deliveryPeriodId?: string;

    pageSize?: number;
    offset?: number;
    filledStartDate?: Date;
    filledEndDate?: Date;
}

export type SortModel = {
    colId: string,
    sort: string
}

export type RollOffersRequest = {
    offerIds: number[];
    rollToDeliveryPeriodId: string;
    rollToFuturesContractId?: number;
    spread?: number;
}

const offerService = new OfferService();
export default offerService;