import Event from "common/event";

export class ApiCallStarted extends Event {
  constructor() {
    super(ApiCallStarted.EventName);
  }

  public static EventName: string = "API_CALL_STARTED";
}

export class ApiCallSucceeded extends Event {
    constructor() {
      super(ApiCallSucceeded.EventName);
    }
  
    public static EventName: string = "API_CALL_SUCCEEDED";
}

export class ApiCallFailed extends Event {
    constructor() {
      super(ApiCallFailed.EventName);
    }
  
    public static EventName: string = "API_CALL_FAILED";
}

export class ApiCallCheckStatus extends Event {
    constructor() {
      super(ApiCallCheckStatus.EventName);
    }
  
    public static EventName: string = "API_CALL_CHECK_STATUS";
}

