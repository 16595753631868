import { getConnectionManager, HubConnectionManager } from 'common/streaming/hubConnectionManager';
import IDispatcher from "common/dispatcher";
import deliveryPeriodService from 'features/bids/deliveryPeriod.service';
import MemoizeDebounce from 'common/decorators/memoizedDebounce';
import { DeliveryPeriodAdded, DeliveryPeriodDeletedWithId, DeliveryPeriodUpdated } from 'features/bids/deliveryPeriod.actions';
import DeliveryPeriod from 'domain/deliveryPeriod';
import { Subject } from 'rxjs';

export interface IDeliveryPeriodEventStreamingService {
    start(dispatcher: IDispatcher): Promise<any>;
    deliveryPeriodCreated: Subject<DeliveryPeriod>;
    deliveryPeriodUpdated: Subject<DeliveryPeriod>;
    deliveryPeriodDeleted: Subject<string>;
}

export const DeliveryPeriodEvents = {
    Created: "DeliveryPeriodCreated",
    Updated: "DeliveryPeriodUpdated",
    Deleted: "DeliveryPeriodDeleted"
}

const created = new Subject<DeliveryPeriod>();
const updated = new Subject<DeliveryPeriod>();
const deleted = new Subject<string>();

export class DeliveryPeriodEventStreamingService implements IDeliveryPeriodEventStreamingService {
    private hubConnectionManager: HubConnectionManager;
    private isStarted: boolean;

    public get deliveryPeriodCreated(): Subject<DeliveryPeriod> { return created; }
    public get deliveryPeriodUpdated(): Subject<DeliveryPeriod> { return updated; }
    public get deliveryPeriodDeleted(): Subject<string> { return deleted; }

    constructor() {
        this.hubConnectionManager = getConnectionManager();
    }

    public async start(dispatcher: IDispatcher): Promise<any> {
        if (this.isStarted) return;

        this.isStarted = true;

        var conn = await this.hubConnectionManager.getConnectionAsync();
        conn.on(DeliveryPeriodEvents.Created, ev => this.onDeliveryPeriodAdded(ev.deliveryPeriodId, dispatcher));
        conn.on(DeliveryPeriodEvents.Updated, ev => this.onDeliveryPeriodUpdated(ev.deliveryPeriodId, dispatcher));
        conn.on(DeliveryPeriodEvents.Deleted, ev => this.onDeliveryPeriodDeleted(ev.deliveryPeriodId, dispatcher));
    }

    @MemoizeDebounce(1000, true, false)
    private async onDeliveryPeriodAdded(deliveryPeriodId: string, dispatcher: IDispatcher) {
        const deliveryPeriod = await deliveryPeriodService.getDeliveryPeriod(deliveryPeriodId); 
        dispatcher.dispatch(new DeliveryPeriodAdded(deliveryPeriod).toObject());
        created.next(deliveryPeriod);
    }

    @MemoizeDebounce(1000, true, false)
    private async onDeliveryPeriodUpdated(deliveryPeriodId: string, dispatcher: IDispatcher) {
        const deliveryPeriod = await deliveryPeriodService.getDeliveryPeriod(deliveryPeriodId); 
        dispatcher.dispatch(new DeliveryPeriodUpdated(deliveryPeriod).toObject());
        updated.next(deliveryPeriod);
    }

    @MemoizeDebounce(1000, true, false)
    private async onDeliveryPeriodDeleted(deliveryPeriodId: string, dispatcher: IDispatcher) {
        dispatcher.dispatch(new DeliveryPeriodDeletedWithId(deliveryPeriodId).toObject());
        deleted.next(deliveryPeriodId);
    }
}

const deliveryPeriodEventStreamingService: IDeliveryPeriodEventStreamingService = new DeliveryPeriodEventStreamingService();
export default deliveryPeriodEventStreamingService;