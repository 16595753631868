import { MerchantThin } from "domain/merchant";
import ProducerInvitation from "./producerInvitation";
import { ProducerAccount } from "./producerAccount";

export class Producer {
  id: string = "";
  merchant: MerchantThin = null;
  userId: string = "";
  firstName: string = null;
  lastName: string = null;
  businessName: string = null;
  accountIds: string[] = [];
  phone: string = "";
  postalCode: string = ""; 
  email: string = "";
  canMakeOffer: boolean = false;
  canLogin: boolean = false;
  maxOfferQuantity: number = 0;
  maxErpQuantity: number = 0;
  maxDailyQuantity: number = 0;
  maxCropYearQuantity: number = 0;
  preferredLocationId : string = null;
  preferredTraderId: string= null;
  useBusinessName: boolean = false;
  isActive: boolean = false;
  additionalEmails: string = null;
  additionalPhones: string = null;
  producerStatus: ProducerStatus = 0;
  signupStatus: SignupStatus = null;
  producerInvitation: ProducerInvitation = null;
  accounts: ProducerAccount[] = [];
};

export enum ProducerStatus {
  NotInvited = 0,
  Pending,
  Active,
  Removed
}

export const AllProducerStatuses: ProducerStatus[] = [
  ProducerStatus.NotInvited,
  ProducerStatus.Pending,
  ProducerStatus.Active,
  ProducerStatus.Removed
];

export enum SignupStatus {
  UserRegistered = 1,
  PasswordVerified,
  CanLogin
}

export function getProducerStatusDescription(status: ProducerStatus): string {
  switch (status) {
    case ProducerStatus.NotInvited:
      return "Not Invited";
    case ProducerStatus.Pending:
      return "Pending";
    case ProducerStatus.Active:
      return "Active";
    case ProducerStatus.Removed:
      return "Removed";
    default:
      return "";
  }
}

export function getProducerDisplayName(producer: Producer): string {
  if (producer == null)
    return "";
  return producer.useBusinessName ? `${producer.businessName}` : `${producer.lastName}, ${producer.firstName}`;
}

export function getProducerStatus(producer: Producer): string {
  if (producer == null) return "";
  return getProducerStatusDescription(producer.producerStatus);
}